import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card} from "antd";
import {
  CardTitle,
  Card as RCard,
  CardBody,
  Row,
  Col,
} from "reactstrap";
//actions
import {
  getApplicantMatric,
  getApplicantMatricCleanup,
} from "../../../store/actions/applicant-matric";

//utils
import { formatCurrency } from "../../../utils/helpers";

const ApplicantMetrics = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const getApplicantMatricState = useSelector((s) => s.getApplicantMatric);
  useEffect(() => {
    dispatch(getApplicantMatric(1));
  }, []);

  useEffect(() => {
    if (getApplicantMatricState.isSuccessful) {
      setData(getApplicantMatricState.data);
      dispatch(getApplicantMatricCleanup());
    } else if (getApplicantMatricState.error) {
      dispatch(getApplicantMatricCleanup());
    }
  }, [getApplicantMatricState]);

  return (
    <>
      <div className="pt-5  container">
        <div className="pt-5 table-container">
          <Card>
            <CardTitle className="text-cente p-4">
              <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
                <div> Applicant Metrics </div>
                <div></div>
              </div>
            </CardTitle>
          </Card>
          <hr />
          <Card>
            <Row>
            <Col className="h-25" lg="6" md="6" sm="6">
                <RCard className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-success">
                          <i className="fa fa-bar-chart text-primary" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Net Funding</p>
                          <CardTitle tag="p">
                            {data && data.Net_Funding__c
                              ? formatCurrency(data.Net_Funding__c)
                              : 0}
                          </CardTitle>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </RCard>
              </Col>
              <Col className="h-25" lg="6" md="6" sm="6">
                <RCard className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-money-coins text-danger" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Total Purchased Amount</p>
                          <CardTitle tag="p">
                            {data && data.Total_Credits_Settled__c
                              ? formatCurrency(data.Total_Credits_Settled__c)
                              : 0}
                          </CardTitle>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </RCard>
              </Col>
              <Col className="h-25" lg="6" md="6" sm="6">
                <RCard className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-success">
                          <i className="fa fa-check text-success" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Total Debits Settled</p>
                          <CardTitle tag="p">
                            {data && data.Total_Debits_Settled__c
                              ? formatCurrency(data.Total_Debits_Settled__c)
                              : 0}
                          </CardTitle>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </RCard>
              </Col>
              <Col className="h-25" lg="6" md="6" sm="6">
                <RCard className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-secondary">
                          <i className="fa fa-list text-secondary" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">
                            Total Outstanding Amount
                          </p>
                          <CardTitle tag="p">
                            {data && data.Total_Outstanding_Amount__c
                              ? formatCurrency(data.Total_Outstanding_Amount__c)
                              : 0}
                          </CardTitle>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </RCard>
              </Col>
              <Col className="h-25" lg="6" md="6" sm="6">
                <RCard className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-success">
                          <i className="fa fa-money text-warning" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Free Balance</p>
                          <CardTitle tag="p">
                            {data && data.Fee_Balance__c
                              ? formatCurrency(data.Fee_Balance__c)
                              : 0}
                          </CardTitle>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </RCard>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </>
  );
};
export default ApplicantMetrics;
