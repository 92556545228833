import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';
export const myAdvancesStart = () => ({
  type: types.MY_ADVANCES_START
});

export const myAdvancesSuccess = payload => ({
  type: types.MY_ADVANCES_SUCCESS,
  payload
});

export const myAdvancesFail = payload => ({
  type: types.MY_ADVANCES_FAIL,
  payload
});

export const myAdvancesCleanup = () => ({
  type: types.MY_ADVANCES_CLEANUP
});

export const myAdvances = ()=> async dispatch => {
  try {
    dispatch(myAdvancesStart());
    const requestObj = {
      path: `submission`,
      method: "GET",   
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(myAdvancesSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(myAdvancesFail(error));
  }
}
