import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Tag, Space, Card, Input, Button } from "antd";
import Highlighter from "react-highlight-words";
import { CardTitle } from "reactstrap";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";

//actions
import {
  getRepayments,
  getRepaymentsCleanup,
} from "../../../store/actions/get-repayments";

//utils
import { formatCurrency } from "../../../utils/helpers";

const Repayments = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const dispatch = useDispatch();
  const getRepaymentsState = useSelector((s) => s.getRepayments);

  const getColumnSearchProps = (dataIndex, Title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${Title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (
        Array.isArray(dataIndex) &&
        dataIndex.length === 2 &&
        record[dataIndex[0]] &&
        record[dataIndex[0]].Name
      ) {
        return record[dataIndex[0]].Name.toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      } else if (record[dataIndex]) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      } else {
        return "";
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    dispatch(getRepayments(3, "", 1));
  }, []);

  useEffect(() => {
    if (getRepaymentsState.isSuccessful) {
      setData(getRepaymentsState.data);
      dispatch(getRepaymentsCleanup());
    } else if (getRepaymentsState.error) {
      dispatch(getRepaymentsCleanup());
    }
  }, [getRepaymentsState]);

  const columns = [
    {
      title: "Funded Date",
      dataIndex: "Funded_Date__c",
      key: "Funded_Date__c",
      ...getColumnSearchProps("Funded_Date__c", "Funded_Date__c"),
      sorter: (a, b) =>
        moment(a.Next_Hit_Date__c).unix() - moment(b.Next_Hit_Date__c).unix(),
      render: (text) => <span>{moment(text).format("MMMM Do, YYYY")}</span>,
      width: 200,
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      ...getColumnSearchProps("Name", "Name"),
      render: (text, data) => (
        <Link to={`repayment-details/${data.Id}`}>{text}</Link>
      ),
    },
    {
      title: "Funding Amount",
      dataIndex: "Funding_Amount__c",
      key: "Funding_Amount__c",
      ...getColumnSearchProps("Funding_Amount__c", "Funding_Amount__c"),
      render: (amount) => (
        <div >{amount? formatCurrency(amount) : 0}</div>
      ),
    },
    {
      title: "Payer Account",
      dataIndex: ["Payee_Account__r", "Name"],
      key: "Payee_Account__r",
      ...getColumnSearchProps(["Payee_Account__r", "Name"], "Payer Account"),
    },
    {
      title: "Payoff (%)",
      dataIndex: "Payoff_Text_Only__c",
      key: "Payoff_Text_Only__c",
      ...getColumnSearchProps("Payoff_Text_Only__c", "Payoff"),
    },
  ];
  return (
    <>
      <div className="pt-5  container">
        <div className="pt-5 table-container">
          <Card>
            <CardTitle className="text-cente p-4">
              <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
                <div>Repayments</div>
                <div>
                  {data.length} {data.length < 2 ? "item" : "items"}
                </div>
              </div>
            </CardTitle>
          </Card>
          <hr />
          <Table
            columns={columns}
            loading={getRepaymentsState.isLoading}
            dataSource={data}
            rowKey={(data) => data.Id}
          />
        </div>
      </div>
    </>
  );
};
export default Repayments;
