import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Tag, Space, Card, Input, Button } from "antd";
import Highlighter from "react-highlight-words";
import { CardTitle } from "reactstrap";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";

//actions
import {
  getSyndicatorDeals,
  getSyndicatorDealsCleanup,
} from "../../../store/actions/get-syndicators-deals";

//utils
import { formatCurrency } from "../../../utils/helpers";

const SyndiacationPayablesList = ({ type, id }) => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const dispatch = useDispatch();
  const getSyndicatorDealsState = useSelector((s) => s.getSyndicatorDeals);

  const getColumnSearchProps = (dataIndex, Title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${Title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (
        Array.isArray(dataIndex) &&
        dataIndex.length > 1 &&
        record[dataIndex[0]].Payment__r &&
        record[dataIndex[0]].Payment__r.Settlement_Date__c
      ) {
        return record[dataIndex[0]].Payment__r.Settlement_Date__c.toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      } else if (record[dataIndex]) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      } else {
        return "";
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    dispatch(getSyndicatorDeals(true, type, id, true));
  }, []);

  useEffect(() => {
    if (getSyndicatorDealsState.isSuccessful) {
      setData(getSyndicatorDealsState.data);
      dispatch(getSyndicatorDealsCleanup());
    } else if (getSyndicatorDealsState.error) {
      dispatch(getSyndicatorDealsCleanup());
    }
  }, [getSyndicatorDealsState]);

  const columns = [
    {
      title: "Syndication Payable",
      dataIndex: "Name",
      key: "SyndicationName",
      ...getColumnSearchProps("Name", "Syndication Payable"),
      render: (text, data) => (
        <Link to={`/syndication/${type}/syndication-payable/${data.Id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: "Amount Due",
      dataIndex: "Amount_Due_Formula__c",
      key: "Amount_Due_Formula__c",
      ...getColumnSearchProps("Amount_Due_Formula__c", "Amount Due"),
      render: (text) => <span>{formatCurrency(text === null ? 0 : text)}</span>,
    },
    {
      title: "Payable Status",
      dataIndex: "Payable_Status__c",
      key: "Payable_Status__c",
      ...getColumnSearchProps("Payable_Status__c", "Payable Status"),
      render: (text) => <Tag color={text === "Due" ? "red" : null}>{text}</Tag>,
    },
    {
      title: "Payable Date",
      dataIndex: "Payable_Date__c",
      key: "Payable_Date__c",
      ...getColumnSearchProps("Payable_Date__c", "Payable Status"),
      render: (text) => <Moment format="MMMM Do, YYYY">{text}</Moment>,
      sorter: (a, b) => moment(a.Payable_Date__c).unix() - moment(b.Payable_Date__c).unix()
    },

    {
      title: "Amount Due",
      dataIndex: "Amount_Due_Formula__c",
      key: "Amount_Due_Formula__c",
      ...getColumnSearchProps("Amount_Due_Formula__c", "Amount Due"),
      render: (text) => <span>{formatCurrency(text === null ? 0 : text)}</span>,
    },
    {
      title: "Amount Paid",
      dataIndex: "Amount_Paid__c",
      key: "Amount_Paid__c",
      ...getColumnSearchProps("Amount_Paid__c", "Amount Paid"),
      render: (text) => <span>{formatCurrency(text === null ? 0 : text)}</span>,
    },
    {
      title: "Settlement Date",
      dataIndex: ["Payment__r", "Settlement_Date__c"],
      key: "Payment__r",
      ...getColumnSearchProps(
        ["Payment__r", "Settlement_Date__c"],
        "Settlement Date"
      ),
      render: (text) => <span>{formatCurrency(text === null ? 0 : text)}</span>,
    },
  ];
  return (
    <>
      <div className=" container">
        <div className="table-container">
          <Card>
            <CardTitle className="text-cente p-4">
              <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
                <div>Syndication Payables</div>
                <div>
                  {data.length} {data.length < 2 ? "item" : "items"}
                </div>
              </div>
            </CardTitle>
          </Card>
          <hr />
          <Table
            columns={columns}
            loading={getSyndicatorDealsState.isLoading}
            dataSource={data}
            scroll={{ x: 1300 }}
            rowKey={(data) => data.Id}
          />
        </div>
      </div>
    </>
  );
};
export default SyndiacationPayablesList;
