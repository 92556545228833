import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Tag, Space, Card, Input, Button } from 'antd';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { CardTitle } from 'reactstrap';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

//actions
import { myDeals, myDealsCleanup } from "../../../store/actions/my-deals";

//utils
import { formatCurrency } from '../../../utils/helpers';

const Deals = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const dispatch = useDispatch();
  const myDealsState = useSelector(s => s.myDeals);

  const getColumnSearchProps = (dataIndex, Title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${Title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  useEffect(() => {
    dispatch(myDeals(5));
  }, []);

  useEffect(() => {
    if (myDealsState.isSuccessful) {
      setData(myDealsState.data);
      dispatch(myDealsCleanup());
    } else if (myDealsState.error) {
      dispatch(myDealsCleanup());
    }
  }, [myDealsState]);

  const columns = [
    {
      title: "Date",
      dataIndex: "Date_Applied__c",
      key: "date",
      render: text => <span>{moment(text).format('MMMM  Do, YYYY')}</span>,
      sorter: (a, b) => moment(a.Date_Applied__c).unix() - moment(b.Date_Applied__c).unix()

    },
    {
      title: "Description",
      dataIndex: "Name",
      key: "Description",
      ...getColumnSearchProps("Name", "Description"),
      render: (text, Details) => <Link to={`/referrer/deal-details/${Details.Id}`}>{text}</Link>,
    },
    {
      title: "Stage",
      key: "StageName",
      dataIndex: "StageName",
      render: (StageName) => (
        <Tag color={StageName === "Approved" ? "green" : ""}>{StageName}</Tag>
      ),
      filters: [
        "Approved",
        "Submitted",
        "Re-Submission",
        "Revisit Offer",
        "Application In",
        "Application Missing Info",
        "On Hold",
        "Offer Sent",
        "Underwriting",
        "Agreement Requested",
        "Agreement Sent",
        "Agreement Signed",
        "Completed File",
      ].map((e) => ({ text: e, value: e, key: e })),
      onFilter: (value, record) => record.StageName.indexOf(value) === 0,
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "AmountRequested",
      ...getColumnSearchProps("Amount", "Amount"),
      render: text => <span>{formatCurrency(text)}</span>,
    },
  ];

  return (
    <>
      <div className='pt-5  container'>
        <div className='pt-5 table-container'>
          <Card>
            <CardTitle className='text-cente p-4'>
              <div className='sos-text-primary mb-0 font-weight-bold d-flex justify-content-between'>
                <div>Referred Opportunities</div>
                <div>
                  {data.length} {data.length < 2 ? 'item' : 'items'}
                </div>
              </div>
            </CardTitle>
          </Card>
          <hr />
          <Table
            columns={columns}
            loading={myDealsState.isLoading}
            dataSource={data}
            scroll={{ x: 600 }}
            rowKey={(data) => data.Id}
          />
        </div>
      </div>
    </>
  );
};
export default Deals;
