import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Tag, Space, Card, Input, Button, message, Modal } from 'antd';
import Highlighter from 'react-highlight-words';
import { CardTitle } from 'reactstrap';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';

//components
import UploadComponent from "../../../../components/UploadComponent/index";

//actions
import {
  myISOStipulation,
  myISOStipulationCleanup,
} from '../../../../store/actions/my-iso-stipulation';
import {
  getSingleStipulationAttachment,
} from "../../../../store/actions/get-single-stipulation-attachment";
import {
  addStipulationAttachment,
  addStipulationAttachmentCleanup,
} from "../../../../store/actions/add-stipulation-attachment";

const DealStipulations = ({ id }) => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [stipId, setStipID]=useState("")
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const dispatch = useDispatch();
  const myISOStipulationState = useSelector(s => s.getMyISOStipulation);
  const addStipulationAttachmentState = useSelector(
    (s) => s.addStipulationAttachment
  );
  const uploadAttachment = () => {
    if (!acceptedFiles.length) return;
    dispatch(addStipulationAttachment(1, 1, stipId, { documents: acceptedFiles }));
  };
  
  const showModal = (Id) => {
    setStipID(Id)
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const getColumnSearchProps = (dataIndex, Title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${Title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  useEffect(() => {
    dispatch(myISOStipulation(1, 1, id));
  }, []);

  useEffect(() => {
    if (myISOStipulationState.isSuccessful) {
      setData(myISOStipulationState.data);
      dispatch(myISOStipulationCleanup());
    } else if (myISOStipulationState.error) {
      dispatch(myISOStipulationCleanup());
    }
  }, [myISOStipulationState]);
  useEffect(() => {
    if (addStipulationAttachmentState.isSuccessful) {
      if (isModalVisible) {
        message.success("Stipulation attachment uploaded successfully", 2);
        setIsModalVisible(false);
      } else {
        message.success(
          "Stipulation attachment uploaded successfully",
          2
        );
      }
      dispatch(getSingleStipulationAttachment(2, 1, id));
      dispatch(addStipulationAttachmentCleanup());
    } else if (addStipulationAttachmentState.error) {
      message.error(`Oops! ${addStipulationAttachmentState.error}`, 2.5);
      dispatch(addStipulationAttachmentCleanup());
    }
  }, [addStipulationAttachmentState]);

  const columns = [
    {
      title: 'Stips Name',
      dataIndex: 'Name',
      key: 'Name',
      render: (Name) => <div style={{ width: '300px' }}>{Name}</div>,
      sorter: (a, b) => a.Name - b.Name,
    },
    {
      title: 'Status',
      key: 'Status__c',
      dataIndex: 'Status__c',
      render: (Status__c) => (
        <Tag color={Status__c === 'Approved' ? 'green' : ''}>{Status__c}</Tag>
      ),
      filters: ['Approved', 'Pending', 'Requested'].map((e) => ({
        text: e,
        value: e,
        key: e,
      })),
      onFilter: (value, record) => record.Status__c.indexOf(value) === 0,
    },
    {
      title: 'Requested By',
      dataIndex: 'Requested_by__c',
      key: 'Requested_by__c',
      sorter: (a, b) => a.Requested_by__c - b.Requested_by__c,
    },
    {
      title: 'Date Requested',
      dataIndex: 'Date_Applied__c',
      key: 'date',
      render: text => <span>{text ? moment(text).format('MMMM Do, YYYY') : 'N/A'}</span>,
      sorter: (a, b) => moment(a.Date_Applied__c).unix() - moment(b.Date_Applied__c).unix(),
    },
    {
      title: 'Submission',
      dataIndex: 'Submission__r',
      key: 'Submission__r',
      render: (Submission__r) => Submission__r && Submission__r.Name,
    },
    {
      title: 'Received',
      dataIndex: 'Recv_d__c',
      key: 'Recv_d__c',
      render: val => <span>{val === true ? 'Yes' : 'No'}</span>,
    },
    {
      title: 'Notes',
      dataIndex: 'Notes__c',
      key: 'Notes__c',
    },
    {
      title: 'Attachments',
      dataIndex: 'Id',
      key: 'Id',
      ...getColumnSearchProps('Name', 'Description'),
      render: Id => <Link to={`/user/stipulation-attachments/${Id}`}>View</Link>,
    },
    {
      title: "Add Attachments",
      dataIndex: "Id",
      key: "Id",
      ...getColumnSearchProps("Name", "Description"),
      render: (Id) => (
        <Button className="m-1" type="dashed" onClick={() => showModal(Id)}>
          Add <PlusOutlined />
        </Button>
      ),
    },
  ];

  return (
    <div className='container'>
      <div className='table-container'>
        <Card>
          <CardTitle className='text-cente'>
            <div className='sos-text-primary mb-0 font-weight-bold d-flex justify-content-between'>
              <div> Stipulations </div>
              <div>
                {data.length} {data.length < 2 ? 'item' : 'items'}
              </div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Table
          columns={columns}
          loading={myISOStipulationState.isLoading}
          dataSource={data}
          scroll={{ x: 1000 }}
          rowKey={(data) => data.Id}
        />
      </div>
      <Modal
        title={null}
        footer={null}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <UploadComponent
          setAcceptedFiles={setAcceptedFiles}
          onSubmit={uploadAttachment}
          disabled={addStipulationAttachmentState.isLoading}
          title="Please attach your file"
        />
      </Modal>
    </div>
  );
};

export default DealStipulations;
