import './index.css';
import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

const PrivacyPolicy = () => {

  return (
    <div className='container privacy-policy'>
      <div className="content">
        <Card className='p-5'>
          <CardTitle className='text-center p-4'>
            <h2 className='sos-text-primary mb-0 font-weight-bold'>SOS Capital Privacy Policy</h2>
          </CardTitle>
          <CardBody>
            <div>
              <h4 className='sos-font-weight-700 sos-text-semi-medium'>Overview</h4>
              <p className='sos-text-medium'>
                This Privacy Policy (“Policy”) applies to data collected by SOS Capital, Inc. doing business under 
                the SOS Capital brand (collectively with their respective subsidiaries and affiliates, “SOS Capital,” 
                “Us,” Our” or We”), a provider of business loan, line of credit and merchant cash advance products and 
                related services (collectively “Services”).  The Policy has two parts:
              </p>
              <ul className='sos-text-medium'>
                <li>
                  <strong>Part 1</strong> provides our U.S. <strong>Privacy Policy</strong>, including our Privacy Policy for Ondeck.com (“Website”), 
                  which is owned by  Enova and operated by OnDeck. This Policy describes how OnDeck collects and uses 
                  the personal information you provide (directly or through third parties), or which is automatically 
                  generated when you interact with our Website, and our mobile application (“Mobile App”).
                </li>
                <li>
                  <strong>Part 2</strong> is our California Supplement, which provides our <strong>California Consumer Privacy Act of 
                  2018 (“CCPA”) Privacy Policy</strong>. The CCPA  provides various rights to consumers who reside in California with 
                  regard to personal information.  A natural person, including a  business owner or guarantor, is considered to 
                  be a consumer under the CCPA.  Any terms defined in the CCPA have the same meaning when used in Part 2.
                </li>
              </ul>
            </div>
            <div>
              <h4 className='sos-font-weight-700 sos-text-semi-medium'>Part 1: SOS Capital US Privacy Policy</h4>
              <p className='sos-text-medium'>
                This Privacy Policy describes the choices available to you regarding our use of your personal information and 
                how you can access and update such information.  For purposes of this Privacy Policy, “Personal Information” means 
                information that personally identifies you, including your contact information, IP address, and loan application 
                information. A large portion of the Personal Information we collect, use, share, and store is sensitive in nature, 
                including your social security number and financial account information (“Sensitive Personal Information”).
              </p>
              <h4 className='sos-font-weight-700 sos-text-semi-medium'>Information We Collect</h4>
              <p className='sos-text-medium'>
                  We collect the following information from you:
                  <ul>
                    <li>
                      Contact information such as name, business name, email address, mailing address, and phone number;
                    </li>
                    <li>
                      Financial information such as bank statements, credit card statements, your average bank balance, personal 
                      and business credit history, payment behavior, bank account information, and tax ID number;
                    </li>
                    <li>
                      Social security number;
                    </li>
                    <li>
                      Unique identifiers such as user name and password, and IP address;
                    </li>
                    <li>
                      Business information including company size, and business type, and
                    </li>
                    <li>
                      Beneficial ownership information*
                    </li>
                  </ul>
              </p>
              <p>
                In addition to collecting information via our Website and Mobile App, we may also conduct a site visit to collect 
                information, and obtain information and external data used by lenders or capital providers to process applications and 
                complete and manage transactions, e.g., from credit reporting agencies and other vendors, as well as from social media. 
                Once you are an SOS Capital customer, we may reach out to you for, or otherwise obtain, information if you decide to renew 
                your loan, merchant cash advance or as otherwise needed to provide the Service.
              </p>
              <p>
                * When required in the U.S., we will collect information on the beneficial owners of our business customers for purposes 
                of verifying their identity only. The U.S. Government implemented the beneficial ownership regulation to help deter 
                financial crimes. By complying, we are doing part in upholding the regulation to protect the financial system. Pursuant 
                to our privacy policies and procedures, we will maintain beneficial ownership information in our system of record.
              </p>
              <h4 className='sos-font-weight-700 sos-text-semi-medium'>Use of Information</h4>
              <p className='sos-text-medium'>
                  We use the information we collect to:
                  <ul>
                    <li>
                      Compile, save, use and analyze your Personal Information in both a personally identifiable form and as part of aggregated data;
                    </li>
                    <li>
                      Operate, maintain, improve, and provide to you the Service and to conduct our business, including our own lending or 
                      funding and referring you to third party small business capital providers;
                    </li>
                    <li>
                      Verify your identity and conduct appropriate diligence;
                    </li>
                    <li>
                      Register you as a user and identify you when you sign into your account;
                    </li>
                    <li>
                      Process your business’ application and determine whether or not your business qualifies for one of our loan 
                      or merchant cash advance products;
                    </li>
                    <li>
                      Process your business loan or merchant cash advance product account, including deducting automatic payments;
                    </li>
                    <li>
                      Communicate with you to send order confirmations, respond to customer service requests, send marketing 
                      communications, and send account update notifications;
                    </li>
                    <li>
                      Post and review your comments on our blog;
                    </li>
                    <li>
                      Conduct research and analyses to better understand our customers; and
                    </li>
                    <li>
                      Produce data analytics and reports containing de-identified summaries of Personal Information and other information 
                      that is not Personal Information (such information, “General Information”) that we share with business partners. 
                      General Information may include Personal Information that has been aggregated and will not identify you.
                    </li>
                  </ul>
              </p>
              <h4 className='sos-font-weight-700 sos-text-semi-medium'>Privacy and Sharing of Information</h4>
              <p className='sos-text-medium'>
                As a subsidiary of SOS Capital, Inc, SOS Capital may share your information with SOS Capital, Inc or its affiliated entities 
                for business purposes, such as financial reporting. Should you apply to SOS Capital, Inc or its affiliated entities for a 
                financial product or service, SOS Capital may share your application status, borrowing history, and loan or merchant cash 
                advance performance.
              </p>
              <p className='sos-text-medium'>
                SOS Capital will share your Personal Information with third parties only in the ways that are described in this Privacy 
                Policy and as required by law.  Neither SOS Capital (or any of its affiliated entities) will sell your Personal Information to third parties.
              </p>
              <p className='sos-text-medium'>
                We will provide your Personal Information, which may include application information, to our trusted business partners, 
                third party capital providers of SOS Capital products, service providers, and subsidiaries and affiliates to provide 
                financing and/or to provide services to help us with our business activities, such as offering customer service, processing 
                credit checks or secure data storage, and other similar services. These companies are authorized to use your Personal 
                Information only as necessary to provide these types of services to us. In addition, we may share your basic information 
                (name, company name, phone number, email address and requested loan or merchant cash advance amount) with one of our 
                business partners to market their services to you, provided, however, if you do not want us to share your Personal 
                Information in these circumstances with these business partners, please contact us to opt out by email at 
                <a href='mailto://info@soscapital.com'>info@soscapital.com</a> or by postal mail at the contact information listed below.  In addition, we may also share General 
                Information for the same reasons as we provide Personal Information and for business partners and others to conduct 
                analyses and research and track marketing performance or as otherwise provided in the Tracking Technologies part herein.
              </p>
              <p className='sos-text-medium'>
                Some of our pages utilize framing or white-labeling techniques to serve content from our partners while preserving the look and 
                feel of our Website. For example, our Careers page utilizes white-labeling techniques. Please be aware that in these instances you 
                are providing your Personal Information to these third parties and not to SOS Capital.
              </p>
              <p className='sos-text-medium'>
                We may also disclose your Personal Information:
                <ul>
                  <li>
                    As required by law, such as to comply with a subpoena, or similar legal process, including in a bankruptcy proceeding;
                  </li>
                  <li>
                    When we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of 
                    others, investigate fraud, or respond to a request from a governmental entity;
                  </li>
                  <li>
                    If SOS Capital is involved in a merger, acquisition, or sale of all or substantially all of its assets or equity, in which 
                    case, you will be notified via email and/or a prominent notice on our Website of any change in ownership or uses of your 
                    Personal Information, as well as any choices you may have regarding your Personal Information; and
                  </li>
                  <li>
                    To any other third party with your prior consent to do so.
                  </li>                  
                </ul>
              </p>
              <h4 className='sos-font-weight-700 sos-text-semi-medium'>User Access and Choice</h4>
              <p className='sos-text-medium'>
                If your Personal Information changes, or if you no longer desire our Service, you may correct, update, amend, delete/remove, 
                ask to have it removed from a public forum or testimonial on our Website or deactivate it by emailing our Customer Support 
                info@soscapital.com or by contacting us by postal mail at the contact information listed below. We will respond to your 
                request within 30 days.
              </p>
              <p className='sos-text-medium'>
                We will retain your information for as long as your account is active or as long as reasonably useful for commercial purposes. 
                We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
              </p>
              <p className='sos-text-medium'><i>Opt-Out Policy</i></p>
              <p className='sos-text-medium'>
                SOS Capital and our business partners may contact you to offer you products and services that may be of interest to you. 
                Out of respect for your privacy, you may choose to stop receiving our newsletter or marketing emails, telephone solicitations 
                or other forms of contact from SOS Capital and our trusted business partners by following the unsubscribe instructions 
                included in these emails, accessing the email preferences in your account settings page or by contacting us by email at 
                info@soscapital.com or by postal mail at the contact information listed below.
              </p>
              <h4 className='sos-font-weight-700 sos-text-semi-medium'>Tracking Technologies</h4>
              <p className='sos-text-medium'>
                SOS Capital and our partners (for example, our marketing partners), affiliates, or analytics or service providers, including 
                our live chat, marketing trackers, and survey tool service providers use cookies or similar technologies. These technologies 
                are used in analyzing trends, administering the Website, tracking users’ movements around the Website and to gather demographic 
                information about our user base as a whole. We may receive reports based on the use of these technologies by these companies on 
                an individual as well as aggregated basis.
              </p>
              <p className='sos-text-medium'>
                A cookie is a small text file that is stored on a user’s computer for record-keeping purposes. We use cookies for user authentication 
                and analytics. Users can control the use of cookies at the individual browser level. If you reject cookies, you may still use our Website, 
                but your ability to use some features or areas of our Website may be limited. If you would like to learn more about cookies or how to 
                configure your browser, please visit <a href='https://www.allaboutcookies.org'>www.allaboutcookies.org</a>.
              </p>
              <p className='sos-text-medium'><i>Log Files</i></p>
              <p className='sos-text-medium'>
                As is true of most websites, we gather certain information automatically and store it in log files. This information may include internet 
                protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or 
                clickstream data. We may combine this automatically collected log information with other information we collect about you. We do this to 
                improve services we offer you and to improve marketing, analytics, and Website functionality.
              </p>
              <p className='sos-text-medium'><i>Behavioral Advertising/Re-Targeting</i></p>
              <p className='sos-text-medium'>
                We partner with third parties to display advertising on our Website and manage our advertising on other sites. Our third-party partners 
                may use technologies such as cookies to gather information about your activities on this Website and other sites in order to provide you 
                advertising based upon your browsing activities and interests. If you wish to not have this information used for the purpose of serving 
                you interest-based ads, you may opt-out by emailing <a href='mailto://info@soscapital.com'>info@soscapital.com</a>. Please note this does not opt you out of being served advertisements. 
                You will continue to receive generic advertisements.
              </p>
              <h4 className='sos-font-weight-700 sos-text-semi-medium'>Security of Information</h4>
              <p className='sos-text-medium'>
                SOS Capital takes the security of your information very seriously. Your information is securely sent to SOS Capital with 128 bit-encryption 
								over the Transport Layer Security (TLS) protocol. This creates an encrypted connection between your browser and our servers. Thus, when you 
								enter Sensitive Personal Information (such as a bank account number) on our order forms, we encrypt the transmission of that information.  
								If you have any questions about the security of your personal information, you can contact us at <a href='mailto://info@soscapital.com'>info@soscapital.com</a>.
              </p>
							<p className='sos-text-medium'>
								If you allow SOS Capital to see your banking information online rather than in paper form, it is on a read-only basis. We do not have access 
                to change, edit, or modify bank account information in any way. SOS Capital employees cannot view your banking username and passwords.
              </p>
              <h4 className='sos-font-weight-700 sos-text-semi-medium'>Additional Information</h4>
              <p className='sos-text-medium'><i>Links to 3rd Party Sites</i></p>
              <p className='sos-text-medium'>
                Our Website includes links to other websites whose privacy practices may differ from those of SOS Capital, including the websites of its 
                affiliated entities. If you submit personal information to any of those sites, your information is governed by their privacy policies. 
                We encourage you to carefully read the privacy policy of any website you visit.
              </p>
              <p className='sos-text-medium'><i>Blog/Forum</i></p>
              <p className='sos-text-medium'>
                Our Website offers publicly accessible blogs or community forums. You should be aware that any information you provide in these areas may 
                be read, collected, and used by others who access them. To request removal of your Personal Information from our blog or community forum, 
                contact us by email <a href='mailto://info@soscapital.com'>info@soscapital.com</a> or by postal mail at the contact information listed below. In some cases, we may not be able to 
                remove your Personal Information, in which case we will let you know if we are unable to do so and why.
              </p>
              <p className='sos-text-medium'><i>Testimonials</i></p>
              <p className='sos-text-medium'>
                We display personal testimonials of satisfied customers on our Website in addition to other endorsements. With your consent, we may post 
                your testimonial along with your name. If you wish to update or delete your testimonial, you can contact us by email at <a href='mailto://info@soscapital.com'>info@soscapital.com</a> 
                or by postal mail at the contact information listed below.
              </p>
              <p className='sos-text-medium'><i>Social Media Widgets</i></p>
              <p className='sos-text-medium'>
                Our Website includes social media features, such as the Facebook “Like” button. These features may collect your IP address, which pages 
                you are visiting on our Website, and may set a cookie to enable the feature to function properly. Social media features and widgets are 
                either hosted by a third party or hosted directly on our Website. Your interactions with these features are governed by the privacy 
                policy of the company providing it.
              </p>
              <p className='sos-text-medium'><i>Changes to This Policy</i></p>
              <p className='sos-text-medium'>
                We may update this Privacy Policy to reflect changes to our information practices. If we make any material changes, we will notify you 
                by email or by means of a notice on this Website prior to the change becoming effective. We encourage you to periodically review this 
                page for the latest information on our privacy practices.
              </p>
              <h4 className='sos-font-weight-700 sos-text-semi-medium'>Contact Information</h4>
              <p className='sos-text-medium'>
                <strong>General Queries:</strong> If you have any questions about this Privacy Policy, the practices of this Website, or your dealings with this 
                Website, please contact us by email at <a href='mailto://info@soscapital.com'>info@soscapital.com</a>, or with a letter by postal mail to:
                <br /><br />
                <i>SOS Capital</i><br />
                <i>ATTN: Marketing</i><br />
                <i>1330 Ave of the Americas, Suite 600</i><br />
                <i>New York, NY 10019</i><br />
                <i>USA</i>
              </p>
              <p className='sos-text-medium'> 
                <strong>To opt-out of marketing communications:</strong>  please use one of the communication methods below, and include your full name and business mailing, email addresses and account number. Once we locate your contact information based on the information you provide us, it will be removed from our databases and mailings lists.
                <ol type='1'>
                  <li>Send an email to <a href='mailto://info@soscapital.com'>info@soscapital.com</a></li>
                  <li>
                    Send a letter to:
                    <br /><br />
                    <i>SOS Capital</i><br />
                    <i>ATTN: Marketing</i><br />
                    <i>1330 Ave of the Americas, Suite 600</i><br />
                    <i>New York, NY 10019</i><br />
                    <i>USA</i>
                  </li>
                </ol>
              </p>
            </div>
            <div>
              <h4 className='sos-font-weight-700 sos-text-semi-medium'>SOS Capital Privacy Policy Part 2: California Supplement:</h4>
              <h4 className='sos-font-weight-700 sos-text-semi-medium'>CCPA Policy</h4>
              <h4 className='sos-font-weight-700 sos-text-semi-medium'>Information We Collect</h4>
              <p className='sos-text-medium'>
                We collect the following categories of information from you, which may include the following:
                <ul>
                  <li>
                    <strong>Identifiers</strong> such as a real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email 
                    address, account name, Social Security number/tax ID number, driver’s license number, passport number or other similar identifiers.
                    <br />
                    We use this information to identify you, process your application and access your personal credit bureau report, 
                    if you are a guarantor or business owner.
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Personal Information</strong> such as your name, signature, social security number, address, telephone number, passport number, 
                    driver’s license or state identification card number, employment history, bank account number or other financial information.
                    <br />
                    We use this information to identify you, process your application and access your personal credit bureau report, if you are a 
                    guarantor or business owner.
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Protected classification characteristics under California or Federal Law</strong> such as age (date of birth).
                    <br />
                    We use this information to confirm your identity and that you are old enough to enter into a loan contract.
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Commercial information</strong>, including records of personal property and personal credit information.
                    <br />
                    We may consider this information when processing your application.
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Internet or other electronic network activity information</strong>, including, but not limited to, browsing history (SOS Capital pages visited), 
                    device ID, device type and information regarding a consumer’s interaction with SOS Capital’s Internet Web site, application or advertisement.
                    <br />
                    We use this information to analyze the use of our website and for fraud screening.
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Geolocation Data</strong>
                    <br />
                    We collect this information for fraud prevention purposes.
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Audio, or similar information</strong>
                    <br />
                    Interactions with SOS Capital sales staff, underwriters and customer service agents are recorded for monitoring and training purposes.
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Professional or employment-related information</strong>
                    <br />
                    We may use this when underwriting our loans or merchant cash advances.
                  </li>
                </ul>
                <ul>
                  <li>
                    <strong>Inferences drawn from any of the information categorized above</strong>
                    <br />
                    This information may be used for credit and fraud analysis and to target future offers.
                  </li>
                </ul>
                Personal information does not include:
                <ul>
                  <li>
                    Publicly available information from government records.
                  </li>
                  <li>
                    De-identified or aggregated information.
                  </li>
                  <li>
                    Information excluded from the CCPA’s scope, such as:
                    <ul>
                      <li>
                        Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPPA).
                      </li>
                      <li>
                        Personal information covered by certain privacy laws, such as the Fair Credit Reporting Act (FCRA), the Gramm-Leach-Bliley 
                        Act (GLBA), the California Financial Information Privacy Act (FIPA) and the Driver’s Privacy Protection Act of 1994.
                      </li>        
                    </ul>
                  </li>
                </ul>
                We obtain the categories of personal information listed above from the following categories of sources:
                <ul>
                  <li>
                    Directly from our applicants, clients, or their agents. For example, information provided as part of the loan 
                    or merchant cash advance application or related processes.
                  </li>
                  <li>
                    Indirectly from our clients or their agents. For example, through information we collect from our clients in the 
                    course of providing services to them.
                  </li>
                  <li>
                    Directly and indirectly from activity on our website (soscapital.com). For example, from submissions though our website 
                    portal or website usage details collected automatically.
                  </li>
                  <li>
                    From third parties that interact with us in connection with the services we perform. For example, from referral partners 
                    and brokers who provide us with loan or merchant cash advance applications or credit reporting agencies that provide us with 
                    information regarding a guarantor’s or business owner’s credit history.
                  </li>
                </ul>
              </p>
              <h4 className='sos-font-weight-700 sos-text-semi-medium'>Use of Information:</h4>
              <p className='sos-text-medium'>
                We use the information we collect to:
                <ul>
                  <li>
                    Compile, save, use and analyze your Personal Information in both a personally identifiable form and as part of aggregated data.
                  </li>
                  <li>
                    Operate, maintain, improve, and provide you with services.
                  </li>
                  <li>
                    Conduct our business, including our own lending, and refer your business to third party small business lenders or capital providers.
                  </li>
                  <li>
                    Verify your identity and conduct appropriate diligence.
                  </li>
                  <li>
                    Review for potentially fraudulent activity.
                  </li>
                  <li>
                    Register you as a user and identify you when you sign in to your account.
                  </li>
                  <li>
                    Process your business’ application and determine whether or not your business qualifies for one of our loan products.
                  </li>
                  <li>
                    Communicate with you to send order confirmations, respond to customer service requests, send marketing communications, 
                    and send account update notifications related to your business.
                  </li>
                  <li>
                    Post and review your comments on our blog.
                  </li>
                  <li>
                    Conduct research and analyses to better understand our customers.
                  </li>
                  <li>
                    Produce data analytics and reports containing de-identified summaries of Personal Information and other information that 
                    is not Personal Information (such information, “General Information”) that we share with business partners. General 
                    Information may include Personal Information that has been aggregated and will not identify you.
                  </li>
                </ul>
              </p>
              <h4 className='sos-font-weight-700 sos-text-semi-medium'>Sharing Personal Information</h4>
              <p className='sos-text-medium'>
                As a subsidiary of SOS Capital, Inc., SOS Capital may share your information with SOS Capital, Inc. or its affiliated entities for business 
                purposes, such as financial reporting. Should you apply to SOS Capital, Inc., its affiliated entities for a financial product or service, SOS 
                Capital may share your application status, borrowing history, and loan or merchant cash advance performance. SOS Capital will share your Personal 
                Information with third parties only in the ways that are described in this Privacy Policy and as required by law. We do not sell your Personal 
                Information to third parties.
              </p>
              <p className='sos-text-medium'>
                We will provide your Personal Information, which may include application information, to our trusted business partners, third party lenders or 
                capital providers of SOS Capital products, service providers, and subsidiaries and affiliates to provide financing and/or to provide services to 
                help us with our business activities, such as offering customer service, processing credit checks or secure data storage, and other similar services. 
                These companies are authorized to use your Personal Information only as necessary to provide these types of services to us. In addition, we may 
                share your basic information (name, company name, phone number, email address and requested loan or merchant cash advance amount) with one of our 
                business partners to market their services to you, provided, however, if you do not want us to share your Personal Information in these circumstances 
                with these business partners you can opt out at the email or mailing address provided in SOS Capital’s Privacy Policy Part 1, which precedes this policy.  
                In addition, we may also share General Information for the same reasons as we provide Personal Information and for business partners and others to conduct 
                analyses and research and track marketing performance or as otherwise provided in the Tracking Technologies part herein.          
              </p>
              <p className='sos-text-medium'>
                Some of our pages utilize framing or white-labeling techniques to serve content from our partners while preserving the look and feel of our Website. 
                For example, our Careers page utilizes white-labeling techniques. Please be aware that in these instances you are providing your Personal Information 
                to these third parties and not to SOS Capital.
              </p>
              <p className='sos-text-medium'>
                We may also disclose your Personal Information:
                <ul>
                  <li>
                    As required by law, such as to comply with a subpoena, or similar legal process, including in a bankruptcy proceeding;
                  </li>
                  <li>
                    When we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, 
                    or respond to a request from a governmental entity;
                  </li>
                  <li>
                    If SOS Capital is involved in a merger, acquisition, or sale of all or substantially all of its assets or equity, in which case, you will be notified 
                    via email and/or a prominent notice on our Website of any change in ownership or uses of your Personal Information, as well as any choices you may 
                    have regarding your Personal Information; and
                  </li>
                  <li>
                    To any other third party with your prior consent to do so.
                  </li>
                </ul>
              </p>
              <p className='sos-text-medium'>
                In the preceding 12 months, we may have disclosed personal information for a business purpose from each of the categories listed previously 
                where we collect information.
              </p>
              <h4 className='sos-font-weight-700 sos-text-semi-medium'>Sale of Personal Information</h4>
              <p className='sos-text-medium'>
                We do not sell personal information.
              </p>
              <h4 className='sos-font-weight-700 sos-text-semi-medium'>Your Rights and Choices</h4>
              <p className='sos-text-medium'>
                The CCPA provides consumers, including owners/guarantors of small business loans or merchant cash advances (California residents), 
                with specific rights regarding their personal information.  SOS Capital is providing these rights to California consumer residents 
                who have provided their personal information as part of an SOS Capital application, loan or merchant cash advance. 
                This section describes the CCPA rights and explains how to exercise those rights. 
              </p>
              <p className='sos-text-medium'><i>Access to Specific Information and Data Portability Rights:</i></p>
              <p className='sos-text-medium'>
                You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months. 
                Once we receive and confirm your verifiable consumer request (“Request”), we will disclose to you:
                <ul>
                  <li>
                    The categories of information we collected about you.
                  </li>
                  <li>
                    The categories of sources for the personal information we collected about you.
                  </li>
                  <li>
                    Our business or commercial purpose for collecting that personal information.
                  </li>
                  <li>
                    The categories of third parties with whom we share that personal information.
                  </li>
                  <li>
                    The specific pieces of personal information we collected about you (also called a data portability request).
                  </li>
                  <li>
                    If we disclosed your personal information for a business purpose, a list identifying the personal 
                    information categories that each category of recipient obtained.
                  </li>
                </ul>
              </p>
              <p className='sos-text-medium'><i>Deletion Request Rights:</i></p>
              <p className='sos-text-medium'>
                You have the right to request that we delete any of your personal information that we collected from you and 
                retained, subject to certain exceptions.  Once we receive and confirm your verifiable consumer request, we will 
                delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.
              </p>
              <p className='sos-text-medium'><i>Exceptions</i></p>
              <p className='sos-text-medium'>
                We may deny your deletion request if retaining the information is necessary for us or our service providers to:
                <ul>
                  <li>
                    Complete the transaction for which we collected the personal information, provide a good or service that you requested, 
                    take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise 
                    perform our contract with you.
                  </li>
                  <li>
                    Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.
                  </li>
                  <li>
                    Debug to identify and repair errors that impair existing intended functionality.
                  </li>
                  <li>
                    Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise 
                    another right provided by law.
                  </li>
                  <li>
                    Comply with the California Electronic Communications Privacy Act (Cal. Penal Code Section 1546 seq.).
                  </li>
                  <li>
                    Engage in public or peer-reviewed scientific, historical or statistical research in the public interest 
                    that adheres to other applicable ethics and privacy laws, when the deletion of the information may likely 
                    render impossible or seriously impair the research’s achievement, if you previously provided informed consent.
                  </li>
                  <li>
                    Enable solely internal uses that are reasonably aligned with expectations based on your relationship with us.
                  </li>
                  <li>
                    Comply with a legal obligation, such as retaining personal information collected to verify/identify 
                    under federal anti-money laundering laws (“AML”).
                  </li>
                  <li>
                    Make other internal and lawful uses of that information that are compatible with the context in 
                    which you provided it.
                  </li>
                </ul>
              </p>
              <p className='sos-text-medium'><i>Exercising Access. Data Portability and Deletion Rights</i></p>
              <p className='sos-text-medium'>
                To exercise the access, data portability and deletion rights described above, please submit a verifiable 
                request to us by one of these methods:
                <ul>
                  <li>
                    Call:
                    <br />
                    <a href='tel:2125355455'>212 535 5455</a> or
                  </li>
                  <li>
                    Email:
                    <br />
                    <a href='mailto://info@soscapital.com'>info@soscapital.com</a>
                  </li>
                </ul>
                Only you or a person registered with the California Secretary of State or other state agency that you 
                authorize to act on your behalf, may make a Request related to your personal information.
              </p>
              <p>
                You may only make a Request for access or data portability twice within a 12-month period.  The Request must:
                <ul>
                  <li>
                    Provide sufficient information that allows us to reasonably verify you are the person about whom we collected 
                    personal information or an authorized representative.
                  </li>
                  <li>
                    Describe your request with sufficient detail that allows us to properly understand, evaluate and respond to it.
                  </li>
                </ul>
                We can’t respond to your request or provide you with personal information if we are unable to verify your identity or authority 
                to make the request and confirm the personal information relates to you.  Making a Request does not require you to create an 
                account with us.  We will only use personal information provided in a Request to verify your identity or authority to make the request.
              </p>
              <p className='sos-text-medium'><i>Response Timing and Format</i></p>
              <p className='sos-text-medium'>
                We endeavor to respond to a Request in writing within 45 days of its receipt.  If we require more time (up to 90 days), we will inform you 
                of the reason and extension period in writing.  If you have an account with us, we will deliver our written response to that account.  
                If you do not have an account with us, we will deliver our written response by mail or electronically, at your option.  Any disclosures 
                we provide will cover the 12-month period preceding the receipt of the Request.  The response we provide will also explain the reasons we 
                can’t comply with a request, if applicable.  For data portability requests, we will select a format to provide the information that is readily 
                useable and should allow you to transmit the information from one entity to another entity without hindrance.
              </p>
              <p className='sos-text-medium'>
                We do not charge a fee to process or response to your Request unless it is excessive, repetitive, or manifestly unfounded.  If we determine that 
                the request warrants a fee, we will tell you why and provide you with a cost estimate before completing your request.
              </p>
              <h4 className='sos-font-weight-700 sos-text-semi-medium'>Non-Discrimination</h4>
              <p className='sos-text-medium'>
                We will not discriminate against you for exercising any of these rights.  Unless permitted by the CCPA, we will not, because you have 
                exercised rights under the CCPA:
                <ul>
                  <li>
                    Deny you goods or services.
                  </li>
                  <li>
                    Charge you different prices for goods or services, including through granting discounts or other benefits, or imposing penalties.
                  </li>
                  <li>
                    Provide you a different level or quality of goods or services.
                  </li>
                  <li>
                    Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.
                  </li>
                </ul>
              </p>
              <h4 className='sos-font-weight-700 sos-text-semi-medium'>Changes to Our CCPA Privacy Notice</h4>
              <p className='sos-text-medium'>
                We reserve the right to amend this privacy notice at our discretion and at any time.  When we make changes to this privacy notice, 
                we will notify you by email or through a notice on our website homepage.
              </p>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default PrivacyPolicy;

