// ----------- import external dependencies ----------
import React, { useRef, useEffect, useState } from "react";
import { phoneRegex } from "../../../utils/regexCheckers";
import {
  Form,
  Row,
  Input,
  Select,
  Col,
  Checkbox,
  Button,
  DatePicker,
  Modal,
  message,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

// ------------ import internal dependencies ------------
import { loadGMapsScript } from "../../../utils/goggleLocation";
import {
  LegalEntityType,
  AmountRequestedPickList,
  IndustryOptions,
} from "../../../utils/dropdown-values";
import industries from '../../../utils/industries';
import { taxIdRegex, SSNRegex } from "../../../utils/regexCheckers";
import { formatTaxId, formatSSN, formatPhone } from "../../../utils/helpers";

// -------- import assets -------
import DropdownIcon from "../../../assets/svg/dropdown.svg";
import OwnerIcon from "../../../assets/svg/owner.svg";
import ReactGA from "react-ga";
import Select2 from 'react-select'

let autoComplete;

function Step2({ previous, cb, defaultValues, step2Values, prevOwnerCount }) {
  const form = step2Values ? step2Values : Form.useForm()[0];
  //const [form] = Form.useForm();
  const { Option } = Select;

  //console.log("MRA defaultValues:", defaultValues);

  const OwnerInitialValue = [
    {
      Owner1FirstName: defaultValues.FirstName,
      Owner1LastName: defaultValues.LastName,
      Owner1Email: defaultValues.Email,
      Owner1Phone: defaultValues.Phone,
      Owner1SSN: "",
      Owner1Ownership: "",
      Owner1Street: "",
      Owner1City: "",
      Owner1State: "",
      Owner1PostalCode: "",
    },
  ];

  // ------- component state managers -------
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState("");
  const [ownerCount, setOwnerCount] = useState(prevOwnerCount);
  const [ownerOpen, setOwnerOpen] = useState(true);
  const [currentOwner, setCurrentOwner] = useState("");
  const [currentOwnerIndex, setCurrentOwnerIndex] = useState(0);
  const [HideContinue, setHideContinue] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  // ------ component refs -------
  const autoCompleteRef = useRef(null);
  const searchCompleteRef = useRef(null);

  /**
   * Handle address change
   * @param {string} addess
   */
  const updateAddressField = (address) => {
    console.log('MRA address:', address);
    setQuery(address);
    // form.setFieldsValue({ address: address });
  };

  const handleSubmitCallBack = (values) => {
    setHideContinue(true);
    message.success("Please Wait.....", 5);
    console.log(values);
    /*
    ReactGA.event({
      category: 'form-sent',
      action: 'Lead Form Submitted',
    });
    console.log('ReactGA.event:');
    */
    cb(values);
  };

  /**
   * Handle update search field
   * @param {string} addess
   */
  const updateSearchField = (address) => {
    // console.log(address, currentOwner);
    setSearch(address);
    // form.setFieldsValue({ address: address });
  };

  /**
   * Handle modal toggle
   * @param {object} e
   */
  const showDisclaimer = (e) => {
    e.preventDefault();
    setModalVisible(true);
  };

  /*
  useEffect(() => {
    if(ownerCount == 1){
      const copyValue = [...form.getFieldValue("otherowners")];
      copyValue[0] = { ...copyValue[0] };
      copyValue[0][`Owner1FirstName`] = defaultValues.FirstName;
      copyValue[0][`Owner1LastName`] = defaultValues.LastName;
      copyValue[0][`Owner1Email`] = defaultValues.Email;
      form.setFields([{ name: `otherowners`, value: [...copyValue], }]);
    }
    //form.setFieldsValue({Owner1Ownership:20});
  },[ownerCount]);
  */

  const formatZipCode = (zipcode) => {
    if (zipcode && zipcode.includes(" ")) {
      return zipcode.split(" ")[1];
    } else {
      return "";
    }
  };

  const formatState = (state) => {
    if (state && state.includes(" ")) {
      return state.split(" ")[0];
    } else {
      return "";
    }
  };

  // update address field for the first owner
  useEffect(() => {
    if (!query) return;

    let address = query.split(", ");
    console.log('MRA cAddress:', address);

    if (address.length > 2) {
      form.setFieldsValue({
        Street: address[0],
        City: address[1],
        State: formatState(address[2]),
        PostalCode: formatZipCode(address[2]),
      });
    }
  }, [query]);

  // update address field for additional owners
  useEffect(() => {
    if (!search) return;
    console.log(search, currentOwner);
    let address = search.split(", ");
    console.log(address);

    if (address.length > 2) {
      const copyValue = [...form.getFieldValue("otherowners")];
      copyValue[currentOwnerIndex] = { ...copyValue[currentOwnerIndex] };
      copyValue[currentOwnerIndex][`Owner${currentOwner}Address`] = address;
      copyValue[currentOwnerIndex][`Owner${currentOwner}Street`] = address[0];
      copyValue[currentOwnerIndex][`Owner${currentOwner}City`] = address[1];
      copyValue[currentOwnerIndex][`Owner${currentOwner}State`] = formatState(address[2]);
      copyValue[currentOwnerIndex][`Owner${currentOwner}PostalCode`] =
        formatZipCode(address[2]);

      // update owners property
      form.setFields([
        {
          name: `otherowners`,
          value: [...copyValue],
        },
      ]);
      console.log(form.getFieldValue("otherowners"));
      console.log(form.getFieldsValue());
    }
  }, [search]);

  const onSSNChange = (e) => {
    const copyValue = [...form.getFieldValue("otherowners")];
    copyValue[currentOwnerIndex] = { ...copyValue[currentOwnerIndex] };
    copyValue[currentOwnerIndex][`Owner${currentOwner}SSN`] = formatSSN(
      e.target.value
    );
    form.setFields([{ name: `otherowners`, value: [...copyValue] }]);
  };

  const onPhoneChange = (e) => {
    const copyValue = [...form.getFieldValue("otherowners")];
    copyValue[currentOwnerIndex] = { ...copyValue[currentOwnerIndex] };
    copyValue[currentOwnerIndex][`Owner${currentOwner}Phone`] = formatPhone(
      e.target.value
    );
    form.setFields([{ name: `otherowners`, value: [...copyValue] }]);
  };

  return (
    <>
      <Form
        layout="vertical"
        className="lead-form"
        form={form}
        defaultValue={{ ...defaultValues }}
        onFinish={handleSubmitCallBack}
        autoComplete="off"
      >
        <Row gutter={32}>
          <Col md={12} sm={24}>
            <Form.Item
              label="Ownership type"
              name="LegalEntityType"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Select defaultValue="">
                {LegalEntityType.map((option) => (
                  <Option key={option.key} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} sm={24}>
            <Form.Item
              label="Tax ID(EIN)"
              name="TaxID"
              rules={[
                // { required: true, message: "This field is required" },
                {
                  required: true,
                  message: "Please input a valid tax Id",
                  pattern: taxIdRegex,
                },
              ]}
            >
              <Input
                placeholder="XX-XXXXXXX"
                onChange={(eve) => {
                  form.setFieldsValue({ TaxID: formatTaxId(eve.target.value) });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col className="gutter-row" md={12} sm={24}>
            <Form.Item
              label="Monthly Revenue"
              name="MonthlyRevenue"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Select defaultValue="">
                {AmountRequestedPickList.map((option) => (
                  <Option key={option.key} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" md={12} sm={24}>
            <Form.Item
              label="Business Inception"
              name="DateBusinessEstablished"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <DatePicker
                style={{ width: "100%" }}
                autoComplete="business"
                inputReadOnly
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col className="gutter-row" md={12} sm={24}>
          <Form.Item
                label="Industry"
                name="Industry2"
                rules={[{ required: true, message: "This field is required" }]}
              >
              <Select2 options={industries} 
               onChange={(eve) => {
                console.log('MRA eve.label:', eve.label);
                form.setFieldsValue({ Industry: eve.label });
              }}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" md={12} sm={24}>
            <span style={{ display: 'none'}}>
            <Form.Item
                label="Industry"
                name="Industry"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select defaultValue="">
                  {industries.map((option) => (
                    <Option key={option.key} value={option.label}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              </span>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col className="gutter-row" md={24} sm={24}>
            <Form.Item label="Business Address">
              <Input
                placeholder="Business Address"
                onFocus={() => {
                  loadGMapsScript(
                    autoComplete,
                    updateAddressField,
                    autoCompleteRef
                  );
                }}
                ref={autoCompleteRef}
                onChange={(event) => setQuery(event.target.value)}
                value={query}
                contentEditable={false}
              />
            </Form.Item>
          </Col>
          {/*
        <Col className="gutter-row" md={12} sm={24}>
          <Form.Item
            label="Percentage Ownership"
            name="Owner1Ownership"
            rules={[
              {
                required: true,
                message: "Please input a valid percentage",
                pattern: new RegExp(/\d{1,3}/),
              },
            ]}
          >
            <Input placeholder="" />
          </Form.Item>
        </Col>
          */}
        </Row>
        <Row gutter={32}>
          <Col className="gutter-row" md={12} sm={24}>
            <Form.Item
              label="Street"
              name="Street"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" md={12} sm={24} name="City">
            <Form.Item
              label="City"
              name="City"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col className="gutter-row" md={12} sm={24}>
            <Form.Item
              label="State/Region"
              name="State"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" md={12} sm={24}>
            <Form.Item
              label="Zipcode"
              name="PostalCode"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>
        </Row>
        
        <div
          className="owners-info"
          onClick={() => setOwnerOpen(!ownerOpen)}
          style={{ cursor: "pointer" }}
        >
          <img src={DropdownIcon} alt="dropdown" />
          <span>Owner’s Information</span>
        </div>

        {/* ------- create dynamic form ------ */}
        <Form.List name="otherowners" initialValue={OwnerInitialValue}>
          {(fields, { add, remove }) => (
            <>
              {ownerOpen &&
                fields.map((key, name, ...restField) => {
                  //console.log('MRA key:', key, ' name:', name);
                  const owner = key.name + 1;
                  //console.log('MRA 001:' , [name, `Owner${owner}Phone`]);
                  return (
                    <React.Fragment key={`fragment${key.key}`}>
                      <div key={`div${key.key}`}>
                        {owner != 1 && (
                          <div className="owner-heading">
                            <img src={OwnerIcon} alt="owner" />
                            <span>{`Owner ${owner}`}</span>
                            <MinusCircleOutlined
                              onClick={() => {
                                setOwnerCount(ownerCount - 1);
                                remove(name);
                              }}
                            />
                          </div>
                        )}
                        {/* ------ dynamic forms ----- */}
                        <Row gutter={32}>
                          <Col className="gutter-row" md={12} sm={24}>
                            <Form.Item
                              hidden={owner == 1 ? true : false}
                              label="First Name"
                              {...restField}
                              name={[name, `Owner${owner}FirstName`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please this field is required",
                                  pattern: new RegExp(/\w+/),
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" md={12} sm={24}>
                            <Form.Item
                              hidden={owner == 1 ? true : false}
                              label="Last Name"
                              {...restField}
                              name={[name, `Owner${owner}LastName`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please this field is required",
                                  pattern: new RegExp(/\w+/),
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={32}>
                          <Col className="gutter-row" md={12} sm={24}>
                            <Form.Item
                              hidden={owner == 1 ? true : false}
                              label="Email Address"
                              {...restField}
                              name={[name, `Owner${owner}Email`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please use a valid email",
                                  pattern: new RegExp(/\S+@\S+\.\S+/),
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" md={12} sm={24}>
                            <Form.Item
                              hidden={owner == 1 ? true : false}
                              label="Phone Number"
                              {...restField}
                              name={[name, `Owner${owner}Phone`]}
                              rules={[
                                {
                                  required: false,
                                  message: "Please put a valid phone number",
                                  pattern: new RegExp(phoneRegex),
                                },
                              ]}
                            >
                              <Input
                                placeholder="XXX-XXX-XXXX"
                                onFocus={() => {
                                  setCurrentOwner(owner);
                                  setCurrentOwnerIndex(key.name);
                                }}
                                onChange={(e) => onPhoneChange(e)}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={32}>
                          <Col className="gutter-row" md={12} sm={24}>
                            <Form.Item
                              label="SSN"
                              {...restField}
                              name={[name, `Owner${owner}SSN`]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please use a valid SSN",
                                  pattern: new RegExp(SSNRegex),
                                },
                              ]}
                            >
                              <Input
                                placeholder="XXX-XX-XXXX"
                                onFocus={() => {
                                  setCurrentOwner(owner);
                                  setCurrentOwnerIndex(key.name);
                                }}
                                onChange={(e) => onSSNChange(e)}
                              />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" md={12} sm={24}>
                            <Form.Item
                              label="Ownership Percentage (%)"
                              {...restField}
                              name={[name, `Owner${owner}Ownership`]}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please this field is required",
                              //     pattern: new RegExp(/\d{1,2}%?/),
                              //   },
                              // ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={32}>
                          <Col className="gutter-row" md={24} sm={24}>
                            <Form.Item
                              label="Home Address"
                              name={[name, `Owner${owner}Address`]}
                            >
                              <Input
                                onFocus={() => {
                                  setCurrentOwner(owner);
                                  setCurrentOwnerIndex(key.name);
                                  loadGMapsScript(
                                    autoComplete,
                                    updateSearchField,
                                    searchCompleteRef
                                  );
                                }}
                                placeholder="Home Address"
                                ref={searchCompleteRef}
                                onChange={(event) => {
                                  // updateSearchField(event.target.value);
                                  setSearch(event.target.value);
                                  // const address =
                                  //   event.target.value.split(", ");
                                  // console.log(address);
                                  // if (address.length > 2) {
                                  //   console.log(address);
                                  //   form.setFieldsValue({
                                  //     [`Owner${owner}Street`]: address[0],
                                  //     [`Owner${owner}City`]: address[1],
                                  //     [`Owner${owner}State`]: address[1],
                                  //     [`Owner${owner}PostalCode`]: address[2],
                                  //   });
                                  // }
                                }}
                                onBlur={(event) => {}}
                                contentEditable={false}
                                //value={search}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={32}>
                          <Col className="gutter-row" md={12} sm={24}>
                            <Form.Item
                              label="Street"
                              {...restField}
                              name={[name, `Owner${owner}Street`]}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" md={12} sm={24}>
                            <Form.Item
                              label="City"
                              {...restField}
                              name={[name, `Owner${owner}City`]}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={32}>
                          <Col className="gutter-row" md={12} sm={24}>
                            <Form.Item
                              label="State/Region"
                              {...restField}
                              name={[name, `Owner${owner}State`]}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" md={12} sm={24}>
                            <Form.Item
                              label="Zipcode"
                              {...restField}
                              name={[name, `Owner${owner}PostalCode`]}
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </React.Fragment>
                  );
                })}

              <Form.Item>
                <Col span={24}>
                  {ownerCount < 2 && (
                    <Button
                      type="primary"
                      ghost
                      size="large"
                      icon={<PlusOutlined />}
                      style={{ marginBottom: "2rem" }}
                      onClick={() => {
                        // if (ownerCount < 3) {
                        setOwnerCount(ownerCount + 1);
                        // }
                        add();
                      }}
                    >
                      Add Owner
                    </Button>
                  )}
                </Col>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Row gutter={32}>
          <Col className="gutter-row" md={12} sm={24}>
            <Form.Item
              label="Signature"
              name="Signature"
              rules={[{ required: true, message: "This field is required" }]}>
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" md={12} sm={24}>
            
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={24}>
            <Form.Item
              name="credit"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  transform: (value) => value || undefined,
                  type: "boolean",
                  message: "Please check the box to continue",
                },
              ]}
            >
              <Checkbox>
                <span className="funding-consent">
                  I authorize SOS to access my credit report (this won’t affect
                  your credit score){" "}
                  <Link to="#" onClick={showDisclaimer}>
                    Credit Authorization Disclaimer
                  </Link>
                </span>
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className="funding-action">
              <Button
                disabled={HideContinue}
                type="link"
                htmlType="button"
                className="btn-tran"
                onClick={() => previous({ ...form }, ownerCount)}
              >
                Previous
              </Button>
              <Button
                id='form-sent'
                className="btn-blue"
                htmlType="submit"
                disabled={HideContinue}
              >
                Continue
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <Modal
        title="Credit Disclaimer"
        visible={modalVisible}
        footer={null}
        onCancel={() => setModalVisible(false)}
      >
        <p>
          Your business is applying generally for credit and not for a specific
          credit product or amount. Any product/amount offered will depend on
          the credit and underwriting standards of the lender.
          <br />
          By clicking “Continue”, you
          <br />
          (i) consent to receiving telemarketing calls and messages, including
          calls using an automatic telephone dialing system, from SOS and those
          acting on its behalf at the telephone number you have provided above
          (including your cellular phone number); agree that this consent
          applies even if the number you have provided is currently on any
          state, federal, or corporate Do-Not-Call registry; and understand that
          you are not required to provide this consent as a condition of
          receiving any credit or services from SOS and that you may apply for
          business credit by contacting us directly; and
          <br />
          (ii) acknowledge that you have read SOS’s{" "}
          <Link to="/terms">Application Agreement</Link> and{" "}
          <Link to="/privacy-policy">Privacy Policy</Link> and understand that
          you may opt out of receiving communications of your choice from SOS as
          provided in the Privacy Policy.
        </p>
      </Modal>
    </>
  );
}

export default Step2;
