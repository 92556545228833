import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Tag, Space, Card, Input, Button } from "antd";
import {Link}from "react-router-dom"
import Currency from "react-currency-formatter";
import Highlighter from "react-highlight-words";
import { CardTitle } from "reactstrap";
import { SearchOutlined } from "@ant-design/icons";
import { myISODeals, myISODealsCleanup } from "../../../store/actions/my-iso-deals";
import moment from "moment";

const MyISODeals = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const dispatch = useDispatch();
  const myISODealsState = useSelector((s) => s.getMyISODeals);

  const getColumnSearchProps = (dataIndex, Title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${Title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    dispatch(myISODeals(2,1));
  }, []);

  useEffect(() => {
    if (myISODealsState.isSuccessful) {
      setData(myISODealsState.data);
      dispatch(myISODealsCleanup());
    } else if (myISODealsState.error) {
      dispatch(myISODealsCleanup());
    }
  }, [myISODealsState]);

  const columns = [
    {
      title: "Date",
      dataIndex: "Date_Applied__c",
      key: "date",
      render: (text) => <span>{moment(text).format('MMMM Do, YYYY')}</span>,
      sorter: (a, b) => moment(a.Date_Applied__c).unix() - moment(b.Date_Applied__c).unix()
    },

    {
      title: "Description",
      dataIndex: "Name",
      key: "Description",
      ...getColumnSearchProps("Name", "Description"),
      render: (text,Details) => <Link to={`/iso/deal-details/${Details.Id}`}>{text}</Link>,
    },
    {
        title: "Fee",
        dataIndex: "Origination_Fee__c",
        key: "Fee",
        ...getColumnSearchProps("Origination_Fee__c", "Fee"),
        render: (amount) => (
            <Currency quantity={amount >= 0 ? amount : 0} currency="USD" />
          ),
      },
  
    
    {
      title: "Stage",
      key: "StageName",
      dataIndex: "StageName",
      render: (StageName) => (
        <Tag color={StageName === "Approved" ? "green" : ""}>{StageName}</Tag>
      ),
      filters: [
        "Approved",
        "Submitted",
        "Re-Submission",
        "Revisit Offer",
        "Application In",
        "Application Missing Info",
        "On Hold",
        "Offer Sent",
        "Underwriting",
        "Agreement Requested",
        "Agreement Sent",
        "Agreement Signed",
        "Completed File",
      ].map((e) => ({ text: e, value: e, key: e })),
      onFilter: (value, record) => record.StageName.indexOf(value) === 0,
    },

    {
      title: "Amount Requested",
      dataIndex: "Amount",
      key: "AmountRequested",
      ...getColumnSearchProps("Amount", "Amount Requested"),
      render: (amount) => (
        <Currency quantity={amount >= 0 ? amount : 0} currency="USD" />
      ),
    },

    {
        title: "ISO Account",
        dataIndex: "ISO__r",
        key: "ISO Account",
        ...getColumnSearchProps("ISO__r", "ISO Account"),
        render: (ISOR) => <div>{ISOR&&ISOR.Name&&ISOR.Name}</div>,
      },
  ];
  

  return (
    <>
      <div className="pt-5  container">
        <div className="pt-5 table-container">
          <Card>
            <CardTitle className="text-cente p-4">
              <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
                <div> My ISO Deals </div>
                <div>
                  {data.length} {data.length < 2 ? "item" : "items"}
                </div>
              </div>
            </CardTitle>
          </Card>
          <hr />
          <Table
            columns={columns}
            loading={myISODealsState.isLoading}
            dataSource={data}
            rowKey={(data) => data.Id}
            scroll={{ x: 240 }}
          />
        </div>
      </div>
    </>
  );
};
export default MyISODeals;
