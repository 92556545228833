import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Space, Card, Input, Button, Tag } from "antd";
import Currency from "react-currency-formatter";
import Moment from "react-moment"
import Highlighter from "react-highlight-words";
import { CardTitle } from "reactstrap";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";

//actions
import {
  myISOStageHistory,
  myISOStageHistoryCleanup,
} from "../../../store/actions/my-iso-stage-history";

const DealStageHistory = ({portal, type, id}) => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const dispatch = useDispatch();
  const getmyISOStageHistorysState = useSelector((s) => s.getMyISOStageHistory);

  const getColumnSearchProps = (dataIndex, Title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${Title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    dispatch(myISOStageHistory(portal, type, id));
  }, []);

  useEffect(() => {
    if (getmyISOStageHistorysState.isSuccessful) {
      setData(getmyISOStageHistorysState.data);
      dispatch(myISOStageHistoryCleanup());
    } else if (getmyISOStageHistorysState.error) {
      dispatch(myISOStageHistoryCleanup());
    }
  }, [getmyISOStageHistorysState]);

  const columns = [
    {
      title: "Stage",
      key: "StageName",
      dataIndex: "StageName",
      render: (StageName) => (
        <Tag color={StageName === "Approved" ? "green" : ""}>{StageName}</Tag>
      ),
      filters: [
        "Approved",
        "Pending",
        "Requested", 
        "Funded", 
      ].map((e) => ({ text: e, value: e, key: e })),
      onFilter: (value, record) => record.Status__c.indexOf(value) === 0,
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      ...getColumnSearchProps("Amount", "Amount"),
      render: (amount) => (
        <Currency quantity={amount ? amount : 0} currency="USD" />
      ),
    },
    {
      title: "Last Modified Date",
      dataIndex: "SystemModstamp",
      key: "lastdate",
      render: (text) => <span>{moment(text).format('MMMM Do, YYYY')}</span>,
      sorter: (a, b) => moment(a.SystemModstamp).unix() - moment(b.SystemModstamp).unix()

    },
  ];

  return (
    <div className="container">
      <div >
        <Card>
          <CardTitle className="text-cente">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
              <div> Stage History </div>
              <div>
                {data.length} {data.length < 2 ? "item" : "items"}
              </div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Table
          columns={columns}
          loading={getmyISOStageHistorysState.isLoading}
          dataSource={data}
          scroll={{ x: 240 }}
          rowKey={(data) => data.Id}
        />
      </div>
    </div>
  );
};

export default DealStageHistory;
