import React from "react"
import { Container, Row, Col } from "reactstrap";

const SubFooter=()=>{
    return(
        <>
         <div className="bg-white footer-row-container  w-100">
          <div className="container text-center text-md-left footer-row-container">
            <Row>
              <Col className="container" md="6">
                <h1 className="footer-h1">Address</h1>
                <h5 className="footer-h5 ">
                  SOS Capital 1330 6th Ave #600b, New York,
                  <br />
                  NY 10019, United States
                </h5>
              </Col>

              <Col md="6">
                <h1 className="footer-h1">Contact info</h1>
                <h5 className="footer-h5 "><a href="mailto:info@soscapital.com?subject=\info@soscapital.com" >Email: info@soscapital.com</a></h5>
                <h5 className="footer-h5 ">Phone: 212 235 5455</h5>
              </Col>
            </Row>
          </div>
        </div>
        </>
    )
}
export default SubFooter