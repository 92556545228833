import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Space, Card, Input, Button } from "antd";
import Currency from "react-currency-formatter";
import Highlighter from "react-highlight-words";
import { CardTitle } from "reactstrap";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
//actions
import {
  getISOOffers,
  getISOOffersCleanup,
} from "../../../store/actions/my-iso-offers";

const MyISOOffers = ({portal, type, id}) => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const dispatch = useDispatch();
  const getISOOffersState = useSelector((s) => s.getMyISOOffers);

  const getColumnSearchProps = (dataIndex, Title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${Title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if(Array.isArray(dataIndex) && record[dataIndex[0]][dataIndex[1]] && record[dataIndex[0]][dataIndex[1]].Name){
        return record[dataIndex[0]][dataIndex[1]].Name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
      }else if(record[dataIndex]){
        return record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
      }else{
        return '';
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    dispatch(getISOOffers(portal, type, id));
  }, []);

  useEffect(() => {
    if (getISOOffersState.isSuccessful) {
      setData(getISOOffersState.data);
      dispatch(getISOOffersCleanup());
    } else if (getISOOffersState.error) {
      dispatch(getISOOffersCleanup());
    }
  }, [getISOOffersState]);

  const columns = [
    {
      title: "Deal Type",
      dataIndex: "Deal_Type__c",
      key: "deal-type",
      ...getColumnSearchProps("Deal_Type__c", "Deal Type"),
    },
    {
      title: "Funding Amount",
      dataIndex: "Amount__c",
      key: "AmountRequested",
      ...getColumnSearchProps("Amount__c", "Funding Amount"),
      render: (amount) => (
        <Currency quantity={amount ? amount : 0} currency="USD" />
      ),
    },
    {
      title: "Payoff Amount",
      dataIndex: "Payoff_Amount__c",
      key: "PayoffAmount",
      ...getColumnSearchProps("Payoff_Amount__c", "Payoff Amount"),
      render: (amount) => (
        <Currency quantity={amount ? amount : 0} currency="USD" />
      ),
    },
    {
      title: "Net Funded Amount",
      dataIndex: "Net_Funded_Amount__c",
      key: "NetAmount",
      ...getColumnSearchProps("Net_Funded_Amount__c", "Net Funded Amount"),
      render: (amount) => (
        <Currency quantity={amount ? amount : 0} currency="USD" />
      ),
    },
    {
      title: "Purchase Amount",
      dataIndex: "Purchase_Amount__c",
      key: "PurchaseAmount",
      ...getColumnSearchProps("Purchase_Amount__c", "Purchase Amount"),
      render: (amount) => (
        <Currency quantity={amount ? amount : 0} currency="USD" />
      ),
    },
    {
      title: "Term (Months)",
      dataIndex: "Term_Months__c",
      key: "Term (Months)",
      ...getColumnSearchProps("Term_Months__c", "Term (Months)"),
    },
    {
      title: "Details",
      dataIndex: "Id",
      key: "Details",
      render: (Id) => <Link to={`/iso/${type}/offer-details/${Id}`}>View</Link>,
    },
    {
      title: "ISO Account",
      dataIndex: ["Opportunity__r", "ISO__r", "Name"],
      key: "ISO Account",
      ...getColumnSearchProps(["Opportunity__r", "ISO__r", "Name"], "ISO Account"),
      render: (text) => <div>{text}</div>,
    },
  ];

  return (
    <div className="container">
      <div >
        <Card>
          <CardTitle className="text-cente">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
              <div> Deal Offers </div>
              <div>
                {data.length} {data.length < 2 ? "item" : "items"}
              </div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Table
          columns={columns}
          loading={getISOOffersState.isLoading}
          dataSource={data}
          scroll={{ x: 240 }}
          rowKey={(data) => data.Id}
        />
      </div>
    </div>
  );
};

export default MyISOOffers;
