import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Tag, Space, Card, Input, Button } from 'antd';
import Highlighter from 'react-highlight-words';
import { CardTitle } from 'reactstrap';
import { SearchOutlined } from '@ant-design/icons';


//actions
import { getSingleOfferSummary, getSingleOfferSummaryCleanup} from '../../../store/actions/get-single-offer-summary';

//utils
import { formatCurrency } from '../../../utils/helpers';

const OfferSummary= ({portal, type, id }) => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const dispatch = useDispatch();
  const getSingleOfferSummaryState = useSelector(s => s.getSingleOfferSummary);

  const getColumnSearchProps = (dataIndex, Title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${Title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  useEffect(() => {
    dispatch(getSingleOfferSummary(portal, type, id ));
  }, []);

  useEffect(() => {
    if (getSingleOfferSummaryState.isSuccessful) {
      setData(getSingleOfferSummaryState.data);
      dispatch(getSingleOfferSummaryCleanup());
    } else if (getSingleOfferSummaryState.error) {
      dispatch(getSingleOfferSummaryCleanup());
    }
  }, [getSingleOfferSummaryState]);

  const columns = [
    {
        title: 'Advance Amount',
        dataIndex: 'Amount__c',
        key: 'Amount__c',
        ...getColumnSearchProps('Amount__c', 'Advance Amount'),
        render: text => <span>{formatCurrency(text)}</span>,
      },
      {
        title: 'Buy Rate',
        dataIndex: 'Buy_Rate__c',
        key: 'Buy_Rate__c',
        ...getColumnSearchProps('Buy_Rate__c', 'Buy Rate'),
      },
      {
        title: 'Sell Rate',
        dataIndex: 'sellRate',
        key: 'sellRate',
        ...getColumnSearchProps('sellRate', 'Sell Rate'),
      },
      {
        title: 'Purchase Rate',
        dataIndex: 'purchaseRate',
        key: 'purchaseRate',
        ...getColumnSearchProps('purchaseRate', 'Purchase Rate'),
      },
      {
        title: 'Purchased Price',
        dataIndex: 'Amount__c',
        key: 'Amount__c',
        ...getColumnSearchProps('Amount__c', 'Purchased Price'),
        render: text => <span>{formatCurrency(text)}</span>,
      },
      {
        title: 'Deal Months',
        dataIndex: 'Term_Months__c',
        key: 'Term_Months__c',
        ...getColumnSearchProps('Term_Months__c', 'Deal Months'),
      },
      {
        title: 'Daily Price',
        dataIndex: 'dailyPurchase',
        key: 'dailyPurchase',
        ...getColumnSearchProps('dailyPurchase', 'Daily Price'),
        render: text => <span>{formatCurrency(text)}</span>,
      },
  ];
  return (
    <>
      <div className=' container'>
        <div className='table-container'>
          <Card>
            <CardTitle className='text-cente p-4'>
              <div className='sos-text-primary mb-0 font-weight-bold d-flex justify-content-between'>
                <div>Offer Summary</div>
                <div>
                  {data.length} {data.length < 2 ? 'item' : 'items'}
                </div>
              </div>
            </CardTitle>
          </Card>
          <hr />
          <Table
            columns={columns}
            loading={getSingleOfferSummaryState.isLoading}
            dataSource={data}
            scroll={{ x: 1300 }}
            rowKey={(data) => data.Id}
          />
        </div>
      </div>
    </>
  );
};
export default OfferSummary;
