import './index.css';
import React from 'react';
import {
  Card,
  CardBody,
  CardTitle
} from 'reactstrap';

const Terms = () => {

  return (
    <div className='container terms-page'>
      <div className="content">
        <Card className='p-5'>
          <CardTitle className='text-center p-4'>
            <h2 className='sos-text-primary mb-0 font-weight-bold'>Agreement Terms</h2>
          </CardTitle>
          <CardBody>
            <div>
              <h4 className='sos-font-weight-700'>1. SOS Capital Terms and Conditions</h4>
              <p className='sos-text-medium'>
                The SOS Capital service and website (each as defined below) are operated by SOS Capital, Inc., or a subsidiary of 
                affiliate thereof (collectively, “SOS Capital”, “we”, or “us”). SOS Capital is in the business of providing customers 
                with small business loans, lines of credit, merchant cash advances and related services (“Service”). SOS Capital is not 
                a broker, investment advisor or financial planner, and we do not provide financial, securities, legal or tax advice. 
                Before making any decision or implementing any strategy you should consider obtaining additional information and advice 
                from your accountant, attorney, and/or other advisors. “You” is defined as the owner of the company completing the application 
                on behalf of the company seeking credit. Unless otherwise noted, any agreements, authorizations or consents made by you or 
                permissions granted to you hereunder also apply and are binding on your company seeking credit directly from SOS Capital, or from 
                a bank or financial partner that originates credit through the SOS Capital platform (“SOS Capital Financial Partner”).
              </p>
            </div>
            <hr className='mb-5 mt-5' />
            <div>
              <h4 className='sos-font-weight-700'>2. Accepting the Terms</h4>
              <p className='sos-text-medium'>
                By using the information, tools, features and functionality located at soscapital.com or subdomains (the “Website”) 
                or by submitting an application for credit to SOS Capital, you signify that you have read, understand and agree to 
                be bound by these terms and conditions (the “Agreement”) for any and all applications for credit to SOS Capital or 
                an SOS Capital Financial Partner. You further agree all information being provided by your company as the borrower 
                and/or by you (either as a guarantor or as an authorized representative of your company) as part of the application 
                process for the Loan or Merchant Cash Advance is true and complete.
              </p>
              <p className='sos-text-medium'>
                You may not use the Service and you may not accept this Agreement if you are under 18 years of age. You represent 
                that you have the capacity to bind the company or entity applying for credit, on behalf of such company or entity. 
                You understand that, other than with regard to loans under the SBA Paycheck Protection Program (“PPP loans”), (a) 
                we will require you to sign a personal guarantee for the credit obtained by your company from SOS Capital, and (b) 
                for term loans and merchant cash advances, we will take a general lien on your business’s assets. Before you continue, 
                you should print or save a copy of this Agreement for your records.
              </p>
              <p className='sos-text-medium'>
                In addition to this Agreement you and your company may enter into other agreements, including a loan agreement, 
                promissory note, or purchase and sale agreement that will govern the terms of your company’s loan, line of credit or 
                merchant cash advance and use of the Service. If there is a conflict between this Agreement and such other agreements 
                that is applicable to specific aspects of the Service, the other agreement shall govern with respect to those specific aspects.
              </p>
              <p className='sos-text-medium'>
                SOS Capital may make changes to this Agreement from time to time. If we do this, the date at the top of this page 
                indicates when the latest revisions were made. Your continued use of the Service or the Website will constitute 
                acceptance of such revisions.
              </p>
            </div>
            <hr className='mb-5 mt-5' />
            <div>
              <h4 className='sos-font-weight-700'>3. Credit and Background Check Authorization</h4>
              <p className='sos-text-medium'>
                You understand and agree that SOS Capital and our agents and assignees are authorized to contact third parties 
                to conduct background checks and other investigative reports, including but not limited to your business credit 
                card sales data as described below, and make credit report inquiries (including requesting personal credit 
                bureau reports about you and any other owner(s) of the business who will be providing a personal guarantee, and 
                business credit bureaus about your company, in either case, from credit reporting agencies and other sources) or 
                for any other lawful purpose. SOS Capital has such authority for the foregoing in connection with any extension of 
                credit to the company on whose behalf you are applying, conducting loan or merchant cash advance file updates, ongoing 
                loan or merchant cash advance reviews, renewal of financing, or referral of your business to third party lenders or capital 
                providers. Upon your written request, we will advise you if we obtained a credit report. You understand and agree that SOS 
                Capital is making no commitment or guarantee that any product, evaluation, or guidance provided by SOS Capital will result 
                in an approval of credit from SOS Capital or any SOS Capital Financial Partner.
              </p>
            </div>
            <hr className='mb-5 mt-5' />
            <div>
              <h4 className='sos-font-weight-700'>4. Privacy, Personal and Company Information</h4>
              <p className='sos-text-medium'>
                For information about SOS Capital’s data protection practices, please read our Privacy Policy, which can be accessed on 
                the Website, as modified from time to time, which is hereby incorporated into this Agreement. The Privacy Policy explains 
                how SOS Capital collects and treats your personal and company information, and protects your privacy, when you access SOS 
                Capital and use the Service. The policy may be updated from time to time at our discretion. Changes will be effective upon 
                posting to the Website.
              </p>
            </div>
            <hr className='mb-5 mt-5' />
            <div>
              <h4 className='sos-font-weight-700'>5. Identity Verification</h4>
              <p className='sos-text-medium'>
                To help the government fight the funding of terrorism and money laundering activities, federal law requires financial institutions 
                to obtain, verify, and record identifying information that identifies each person and entity applying for credit. Therefore, when 
                your company applies for credit, we ask for your name, address, and other information that allows us to identify you and your company. 
                We may also ask for a driver’s license or other identifying documents. You allow us to obtain such information and share the information 
                with third parties to help us verify you and your company’s identity.
              </p>
              <p className='sos-text-medium'>
                In connection with the provision of the Service, users may submit financial information from their business banking account(s) to SOS 
                Capital. SOS Capital and SOS Capital Financial Partners use this information for funding decisions and may require updates to monitor the 
                performance of customers on an ongoing basis. With the Service, users may direct SOS Capital to retrieve your company information maintained 
                online or otherwise by third-party financial institutions or organizations authorized to house such information.
              </p>
            </div>
            <hr className='mb-5 mt-5' />
            <div>
              <h4 className='sos-font-weight-700'>6. Proprietary Rights</h4>
              <p className='sos-text-medium'>
                All content on the Website or used by the Service, including but not limited to text, names, designs, pictures, information and 
                software are the proprietary property of SOS Capital with all rights reserved.
              </p>
              <p className='sos-text-medium'>
                You are permitted to use content delivered to you through the Service or Website only on the Service. You may not copy, 
                reproduce, modify, distribute, or create derivative works from this content. Further, you agree not to reverse engineer or 
                reverse compile any of the Service technology.
              </p>
            </div>
            <hr className='mb-5 mt-5' />
            <div>
              <h4 className='sos-font-weight-700'>7. Electronic Communications</h4>
              <p className='sos-text-medium'>
                You hereby consent to receive by electronic means, (or in writing through postal mail or email), this Agreement, communications, 
                disclosures and notices, including and any and all disclosures and/or notices required to be given by applicable law or regulation. 
                You also consent to allow SOS Capital, or an SOS Capital Financial Partner, to respond to any inquiries by e-mail, at the email 
                address you have provided, regardless of the format of the original inquiry. You must also agree to our ESign consent during the 
                online application process.
              </p>
            </div>
            <hr className='mb-5 mt-5' />
            <div>
              <h4 className='sos-font-weight-700'>8. Telephone Communications</h4>
              <p className='sos-text-medium'>
                You authorize SOS Capital and those acting on its behalf to deliver or cause to be delivered advertisements or telemarketing messages, 
                including calls, text messages, and SMS and MMS messages, using an automatic telephone dialing system (often referred to as an autodialer) 
                or an artificial or prerecorded voice, to the telephone numbers you have provided in your application for credit to SOS Capital (including 
                your cellular phone numbers). You agree that this consent applies even if the numbers you have provided are currently on any state, federal, 
                or corporate Do-Not-Call registry. You understand that you are not required to provide this consent as a condition of receiving any credit or 
                services from SOS Capital, and that you may apply for business credit by contacting us directly. You may opt out of receiving calls and marketing 
                from SOS Capital and its affiliates, marketing partners, agents and others as provided in the Privacy Policy or by contacting us directly.
              </p>
            </div>
            <hr className='mb-5 mt-5' />
            <div>
              <h4 className='sos-font-weight-700'>9. Loan Return Policy</h4>
              <p className='sos-text-medium'>
                You agree that you may not return any loan or other financial product that you take from SOS Capital, or an SOS Capital’s Financial Partner, 
                except at SOS Capital’s or the SOS Capital Financial Partner’s sole discretion, it being understood that in no event will you be able to return 
                any such product after 5:00 p.m. EST on the second business day following the initiation of disbursement of funds by SOS Capital, and no returns 
                of renewal loans, lines of credit or merchant cash advances will be permitted.
              </p>
            </div>
            <hr className='mb-5 mt-5' />
            <div>
              <h4 className='sos-font-weight-700'>10. Third Party Sites or Content</h4>
              <p className='sos-text-medium'>
                The Website or other materials sent to you under the Service may contain links to other Websites (“Third Party Sites”), as well as 
                content from third parties. SOS Capital is not responsible for such Third Party Sites or content from third parties, including without 
                limitation accuracy, reliability, offensiveness, or appropriateness. If you decide to leave the Website and access Third Party Sites 
                or third party content, this Agreement and SOS Capital policies no longer apply.
              </p>
              <p className='sos-text-medium'>
                In addition, if any such link is to another capital provider or lender, SOS Capital acts solely as a referrer and does not guarantee or 
                underwrite the products or services, including credit products, provided by such capital provider or lender. You will be deemed a customer 
                of that capital provider or lender with respect to any products or services provided by such capital provider or lender. SOS Capital has 
                no role in, nor any liability for any decision made or product or service provided by such other capital provider or lender.
              </p>
            </div>
            <hr className='mb-5 mt-5' />
            <div>
              <h4 className='sos-font-weight-700'>11. Miscellaneous</h4>
              <h5 className='sos-font-weight-700'>Limitation on Liability</h5>
              <p className='sos-text-medium'>
                TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY 
                FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST DATA, 
                ARISING FROM YOUR USE OF THE WEBSITE OR THE SERVICES, INCLUDING THE UNAVAILABILITY OF THE SERVICES FOR ANY REASON, OR ANY SYSTEM FAILURE OR 
                MALFUNCTION ASSOCIATED WITH THE SERVICES, AND INCLUDING ANY THIRD PARTY CLAIMS, WHETHER BASED ON THEORIES OF BREACH OF CONTRACT, TORT, PRODUCT 
                LIABILITY, OR BREACH OF WARRANTY. THESE LIMITATIONS OF LIABILITY WILL APPLY EVEN IF WE ARE ADVISED ON THE POSSIBILITY OF SUCH DAMAGES OR CLAIMS. 
                THE PARTIES UNDERSTAND THAT THE SERVICES BY US WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS.
              </p>
              <h5 className='sos-font-weight-700'>Warranty</h5>
              <p className='sos-text-medium'>
                WE DISCLAIM ANY WARRANTY OF MERCHANT LIABILITY OR FITNESS FOR A PARTICULAR PURPOSE, ANY LIABILITY FOR ERRORS OR OMISSIONS IN ANY INFORMATION PROVIDED 
                AS PART OF THE SERVICES OR ON THE WEBSITE AND ANY WARRANTIES REGARDING THE OPERABILITY OF THE SERVICES OR LIABILITY FOR ANY SERVICE INTERRUPTIONS OR 
                SYSTEM FAILURES THAT MAY AFFECT THE SERVICES AT ANY TIME.
              </p>
              <h5 className='sos-font-weight-700'>Governing Law; Venue and Jurisdiction</h5>
              <p className='sos-text-medium'>
                By accessing the Website or using Services, you agree that the laws of the state of New York (or the home state of the SOS Capital Financial Partner), 
                without regard to any principles of conflict of laws that would require or permit the application of laws of any other jurisdiction, will govern this 
                Agreement or your use of the Services.
              </p>
            </div>
            <hr className='mb-5 mt-5' />
            <div>
              <p className='sos-text-medium'>
                <u>For Ohio applicants:</u> You understand that the Ohio laws against discrimination require that all creditors make credit equally available to all 
                credit worthy customers, and that credit reporting agencies maintain separate credit histories on each individual upon request. The Ohio Civil Rights 
                Commission administers compliance with this law.
              </p>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default Terms;

