import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getSyndicatorStart = () => ({
  type: types.GET_SYNDICATOR_START
});

export const getSyndicatorSuccess = payload => ({
  type: types.GET_SYNDICATOR_SUCCESS,
  payload
});

export const getSyndicatorFail = payload => ({
  type: types.GET_SYNDICATOR_FAIL,
  payload
});

export const getSyndicatorCleanup = () => ({
  type: types.GET_SYNDICATOR_CLEANUP
});

export const getSyndicator = portal => async dispatch => {
  try {
    dispatch(getSyndicatorStart());
    const path = `syndicator/${portal}`;
    const requestObj = {
      path,
      method: 'GET'
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(getSyndicatorSuccess(data[0]));
    
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getSyndicatorFail(error));
  }
}
