import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { connect } from 'react-redux';

//images
import noImg from "../../assets/img/no-avatar.png";
import LOGO from '../../assets/img/sos_logo.png';

//actions
import { getProfile, getProfileCleanup } from '../../store/actions/get-profile';

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getCollapseStates(props.routes);
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }

      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the /' (left menu)
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={this.activeRoute(prop.layout === '/public' ? prop.path :  prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout === '/public'? prop.path :  prop.layout + prop.path} activeClassName="" style={{display:!prop.icon&&"none"}}>
            {prop.icon !== undefined ? (
              <>
                <div>
                  <i className={prop.icon} />
                  <p className='text-wrap'>{prop.name}</p>
                </div>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  componentDidMount() {
    if(!localStorage.getItem('authToken')){
      this.props.history.push('/login');
    }

    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    if(!this.props.profile){
      this.props.getProfileCleanup();
      this.props.getProfile();
    }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    const { profile } = this.props;
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo">
          <a
            href="#"
            className="simple-text logo-mini"
          >
            <div className="logo-img">
              <img src={LOGO} />
            </div>
          </a>
          <a
            href="#"
            className="simple-text logo-normal"
            style={{zIndex:"100"}}
          >
            SOS Capital Portal
          </a>
        </div>

        <div className="sidebar-wrapper" ref="sidebar">
               
          <div className="user">
      
            <div className="photo">
              
              <img  src= {`${noImg}`} alt="Avatar" />
          
            </div>
            
            <div className="info">
              <a
                href="#"
                data-toggle="collapse"
                aria-expanded={this.state.openAvatar}
                onClick={() =>
                  this.setState({ openAvatar: !this.state.openAvatar })
                }
              >
                <span className='text-capitalize d-flex'>
                  {profile ? profile.Name : 'Loading...'}
                  <b className="caret" />
                </span>
              </a>
              <Collapse isOpen={this.state.openAvatar}>
                <ul className="nav">
                  {/* <li>
                    <NavLink to="#" activeClassName="">
                      <span className="sidebar-mini-icon">MP</span>
                      <span className="sidebar-normal">My Profile</span>
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink to="/logout" activeClassName="">
                      <span className="sidebar-mini-icon"><i className="fa fa-sign-out"></i></span>
                      <span className="sidebar-normal">Logout</span>
                    </NavLink>
                  </li>
               
                </ul>
              </Collapse>
            </div>
          </div>
          <Nav>
            {this.createLinks(this.props.routes)}
            <hr className='sidebar-bottom-hr mt-5' />
            <li>
              <NavLink to="/portals" activeClassName="">
                <div>
                  <i className="fa fa-home" />
                  <p className='text-wrap'>Portals</p>
                </div>
              </NavLink>
            </li>
          </Nav>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.getProfile.data
})

export default connect(mapStateToProps, { 
  getProfile,
  getProfileCleanup 
})(Sidebar);
