import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Table,
  Space,
  Card,
  Input,
  Button,
  Modal,
  message,
} from "antd";
import moment from "moment";
import Moment from "react-moment";
import Highlighter from "react-highlight-words";
import { CardTitle } from "reactstrap";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import UploadComponent from "../../../components/UploadComponent/index";
import {
  getSingleStipulationAttachment,
  getSingleStipulationAttachmentCleanup,
} from "../../../store/actions/get-single-stipulation-attachment";
import {
  addStipulationAttachment,
  addStipulationAttachmentCleanup,
} from "../../../store/actions/add-stipulation-attachment";

const BrokerSingleStipulationAttachment = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const dispatch = useDispatch();
  const getSingleStipulationAttachmentState = useSelector(
    (s) => s.getSingleStipulationAttachment
  );
  const addStipulationAttachmentState = useSelector(
    (s) => s.addStipulationAttachment
  );
  const uploadAttachment = () => {
    if (!acceptedFiles.length) return;
    dispatch(addStipulationAttachment(3, 1, id, { documents: acceptedFiles }));
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const getColumnSearchProps = (dataIndex, Title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${Title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    dispatch(getSingleStipulationAttachment(3, 1, id));
  }, []);

  useEffect(() => {
    if (addStipulationAttachmentState.isSuccessful) {
      if (isModalVisible) {
        message.success("Stipulation attachment uploaded successfully", 2);
        setIsModalVisible(false);
      } else {
        message.success(
          "Stipulation attachment uploaded successfully",
          2
        );
      }
      dispatch(getSingleStipulationAttachment(3, 1, id));
      dispatch(addStipulationAttachmentCleanup());
    } else if (addStipulationAttachmentState.error) {
      message.error(`Oops! ${addStipulationAttachmentState.error}`, 2.5);
      dispatch(addStipulationAttachmentCleanup());
    }
  }, [addStipulationAttachmentState]);

  useEffect(() => {
    if (getSingleStipulationAttachmentState.isSuccessful) {
      setData(getSingleStipulationAttachmentState.data);
      dispatch(getSingleStipulationAttachmentCleanup());
    } else if (getSingleStipulationAttachmentState.error) {
      dispatch(getSingleStipulationAttachmentCleanup());
    }
  }, [getSingleStipulationAttachmentState]);

  const columns = [
    {
      title: "File Name",
      dataIndex: "Name",
      key: "Name",
      ...getColumnSearchProps("Name", "File Name"),
    },
    {
      title: "Uploaded Date",
      dataIndex: "CreatedDate",
      key: "date",
      render: (text) => <Moment format="MMMM Do, YYYY">{text}</Moment>,
      sorter: (a, b) => moment(a.Date_Applied__c).unix() - moment(b.Date_Applied__c).unix()
    },

    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
  ];

  return (
    <>
      <div className="pt-5  container">
        <div className="pt-5 table-container">
          <Card>
            <CardTitle className="text-cente p-4">
              <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
                <div> Stipulations Attachments </div>
                <div>
                  {data.length} {data.length < 2 ? "item" : "items"}
                </div>
              </div>
            </CardTitle>
          </Card>
          <hr />
          <Button className="m-1" type="dashed" onClick={showModal}>
            Add Attachment <PlusOutlined/>
          </Button>
          <Modal
            title={null}
            footer={null}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <UploadComponent
              setAcceptedFiles={setAcceptedFiles}
              onSubmit={uploadAttachment}
              disabled={addStipulationAttachmentState.isLoading}
              title="Please attach your file"
            />
          </Modal>
          <Table
            columns={columns}
            loading={getSingleStipulationAttachmentState.isLoading}
            dataSource={data}
            rowKey={(data) => data.Id}
            scroll={{ x: 240 }}
          />
        </div>
      </div>
    </>
  );
};
export default BrokerSingleStipulationAttachment;
