import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GoogleButton from "react-google-button";
import { useHistory, Link } from "react-router-dom";
import { Alert } from "antd";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  CardTitle,
} from "reactstrap";
import { Button } from "antd";

//utils
import firebase from "../../utils/firebase";

//actions
import { login, loginCleanup } from "../../store/actions/login";
import { getProfile, getProfileCleanup } from '../../store/actions/get-profile';

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loginState = useSelector((s) => s.login);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const token = localStorage.getItem('authToken');

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let testValid = re.test(String(email).toLowerCase());
    return testValid;
  }

  function validatePassword(password) {
    let testValid = password.trim().length > 0;
    return testValid;
  }

  const SignInWithGoogle = () => {
    const res = firebase.doSignInWithGoogle();
    res.then((data) =>
      data.user.getIdToken(true).then(function (idToken) {
        dispatch(login({ tokenId: idToken }));
      })
    );
  };

  const LoginFunc = () => {
    let emailIsValid = validateEmail(email);
    let passwordValid = validatePassword(password);
    if (emailIsValid && passwordValid) {
      let res = firebase.login(email, password);
      res
        .then((data) =>
          data.user.getIdToken(true).then(function (idToken) {
            dispatch(login({ tokenId: idToken }));
          })
        )
        .catch((err) =>
          setError(
            <Alert
              message={
                "Please enter correct email address and password or click on forgot password to reset if you forgot your details"
              }
              type="error"
            />
          )
        );
    } else if (!emailIsValid) {
      setError(<Alert message={"Incorrect email format"} type="error" />);
    } else if (!passwordValid) {
      setError(<Alert message={"password must not be empty"} type="error" />);
    }
  };

  useEffect(() => {
    document.body.classList.toggle("login-page");
    if(token){
      dispatch(getProfileCleanup());
      dispatch(getProfile());
      history.push('/portals');
    }
  }, []);

  useEffect(() => {
    if (loginState.isSuccessful && loginState.data) {
      dispatch(getProfileCleanup());
      dispatch(getProfile());
      dispatch(loginCleanup());
      history.push("/portals");
    } else if (loginState.error) {
      setError(<Alert message={loginState.error} type="error" />);
      dispatch(loginCleanup());
    }
  }, [loginState]);

  return (
    <>
      <div className="login-page mt-5 pt-5  h-100 pb-5 mb-5">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form action="" className="form">
                <Card className="card-login">
                  <CardTitle className="text-center p-4">
                    <h2 className="sos-text-primary mb-0 font-weight-bold">
                      Login{" "}
                    </h2>
                  </CardTitle>
                  <CardHeader>{error}</CardHeader>
                  <CardBody>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email..."
                        type="email"
                        autoComplete="on"
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        type="password"
                        autoComplete="off"
                      />
                    </InputGroup>
                  </CardBody>
                  <br />
                  <CardFooter style={{ marginTop: "-50px" }}>
                    <Button
                      className="sos-bg-primary sos-border-primary text-white w-100 font-weight-bold mb-3"
                      style={{ height: "45px" }}
                      loading={loginState.isLoading}
                      disabled={loginState.isLoading}
                      onClick={LoginFunc}
                    >
                      Login
                    </Button>

                    <div className="text-center pt-1">
                      <GoogleButton
                        style={{ width: "100%" }}
                        type="dark"
                        onClick={SignInWithGoogle}
                      />
                    </div>
                    <div className="mt-4 text-righ">
                      <FormGroup className="d-flex justify-content-between">
                        <FormGroup check>
                        Don't have account?<Link to="/signup">  Signup</Link>
                        </FormGroup>
                      </FormGroup>
                      <FormGroup>
                        <hr/>
                        <FormGroup className="text-right text-dark" check>
                            <Link to="/forgot-password"> Forgot password?</Link>
                        </FormGroup>
                      </FormGroup>
                    </div>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            background: "#369EE3",
          }}
        />
      </div>
    </>
  );
};

export default LoginPage;
