import './index.css';
import React, { useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col
} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from "antd";

//actions
import { getProfile } from '../../store/actions/get-profile';

//cmponents
import Loader from '../../components/Loader';

const PortalAccess = () => {
  const profile = useSelector(s => s.getProfile.data);
  const profileLoading = useSelector(s => s.getProfile.isLoading);
  const profileError = useSelector(s => s.getProfile.error);
  const dispatch = useDispatch();
  const { Is_Applicant__c, Is_Broker__c, Is_Syndication__c, ISO_Section_Access__c, Is_Referrer__c } = profile || {};
  const history = useHistory();

  useEffect(() => {
    if(!localStorage.getItem('authToken')){
      history.push('/login');
    }
    dispatch(getProfile());
  },[]);

  useEffect(() => {
    if(profile){
      if(Is_Applicant__c && !Is_Broker__c && !Is_Syndication__c && !ISO_Section_Access__c && !Is_Referrer__c ){
        history.push('/user/dashboard');
      }else if(Is_Broker__c && !Is_Applicant__c && !Is_Syndication__c && !ISO_Section_Access__c && !Is_Referrer__c ){
        history.push('/broker/dashboard');
      }else if(Is_Syndication__c && !Is_Applicant__c && !Is_Broker__c && !ISO_Section_Access__c && !Is_Referrer__c ){
        history.push('/syndication/dashboard');
      }else if(ISO_Section_Access__c && !Is_Applicant__c && !Is_Broker__c && !Is_Syndication__c && !Is_Referrer__c ){
        history.push('/iso/dashboard');
      }else if(Is_Referrer__c && !Is_Applicant__c && !Is_Broker__c && !Is_Syndication__c && !ISO_Section_Access__c ){
        history.push('/referrer/dashboard');
      }
    }
  },[profile])

  return (
    <div className='container portal-access-page'>
      <div className="content">
        <Loader isLoading={profileLoading && !profile}>
          <Card className='portal-list-con'>
            {profileError ? <Alert message={profileError} type='error' /> : null}
            <CardBody>
              <h2 className='text-center sos-text-primary sos-font-weight-700'>
                Select Portal
              </h2>
              <div className='card-con'>
                <Row>
                  <Col lg='3' md='6' sm='4' className={`${Is_Applicant__c ? '' : 'd-none'}`}>
                    <Link to='/user/dashboard'>
                      <Card className='p-4 sos-bg-primary text-center'>
                        <CardBody>
                          <i className='fa fa-user fa-5x text-white'></i>
                          <h4 className='text-white sos-font-weight-600'>Applicant <br /> Portal</h4>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                  <Col lg='3' md='6' sm='4' className={`${ISO_Section_Access__c ? '' : 'd-none'}`}>
                    <Link to='/iso/dashboard'>
                      <Card className='p-4 sos-bg-primary text-center'>
                        <CardBody>
                          <i className='fa fa-money fa-5x text-white'></i>
                          <h4 className='text-white sos-font-weight-600'>In-House ISO <br /> Portal</h4>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                  <Col lg='3' md='6' sm='4' className={`${Is_Broker__c ? '' : 'd-none'}`}>
                    <Link to='/broker/dashboard'>
                      <Card className='p-4 sos-bg-primary text-center'>
                        <CardBody>
                          <i className='fa fa-area-chart  fa-5x text-white'></i>
                          <h4 className='text-white sos-font-weight-600'>Broker <br /> Portal</h4>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                  <Col lg='3' md='6' sm='4' className={`${Is_Syndication__c ? '' : 'd-none'}`}>
                    <Link to='/syndication/dashboard'>
                      <Card className='p-4 sos-bg-primary text-center'>
                        <CardBody>
                          <i className='fa fa-rss fa-5x text-white'></i>
                          <h4 className='text-white sos-font-weight-600'>Syndication <br /> Portal</h4>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                  <Col lg='3' md='6' sm='4' className={`${Is_Referrer__c ? '' : 'd-none'}`}>
                    <Link to='/referrer/dashboard'>
                      <Card className='p-4 sos-bg-primary text-center'>
                        <CardBody>
                          <i className='fa fa-share-square-o fa-5x text-white'></i>
                          <h4 className='text-white sos-font-weight-600'>Referrer <br /> Portal</h4>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Loader>
      </div>
    </div>
  );
}

export default PortalAccess;
