// ------------ import external dpendencies ------------
import React from "react";
import { Link } from "react-router-dom";

// ------------- import internal dependencies ------------
// ------ import assets -------
import BrandLogo from "../../assets/img/SOS.logo.png";
import TrustPilot from "../../assets/img/trustpilot.png";
import MailIcon from "../../assets/svg/mail.svg";
import CallIcon from "../../assets/svg/call.svg";
import LocationIcon from "../../assets/svg/location.svg";
import PadLockIcon from "../../assets/svg/padlock.svg";
import InstagramIcon from "../../assets/svg/instagram.svg";
import LinkedinIcon from "../../assets/svg/linkedin.svg";
import FacebookIcon from "../../assets/svg/facebook.svg";
import TwitterIcon from "../../assets/svg/twitter.svg";
import CloseIcon from "../../assets/svg/close.svg";
// ------ import styles ------
import "./style.css";

const AuthSidebar = ({ close }) => {
  return (
    <>
      <aside className="auth-sidebar">
        <img
          src={CloseIcon}
          alt="Close icon"
          onClick={close}
          className="close-auth-sidebar"
        />

        {/* ------- heading section ------ */}
        <div>
          <div className="brand-logo">
            <a href="http://soscapital.com/" target="_blank">
              <img src={BrandLogo} alt="logo" />
            </a>
          </div>
          <a
            href="https://www.trustpilot.com/review/soscapital.com"
            target="_blank"
          >
            <img src={TrustPilot} alt="trustpilot" className="trust-pilot" />
          </a>
        </div>

        {/* -------- contact section -------- */}
        <div>
          <div className="contact-details">
            <img src={MailIcon} alt="mail" />
            <span>Info@soscapital.com</span>
          </div>
          <div className="contact-details">
            <img src={CallIcon} alt="call" />
            <span>212-235-5455</span>
          </div>
          <div className="contact-details">
            <img src={LocationIcon} alt="location" />
            <span>1330 6th Ave #600b, New York, NY 10019</span>
          </div>
        </div>

        {/* -------- privacy and socials section -------- */}
        <div>
          <div className="privacy-info">
            <img src={PadLockIcon} alt="privacy" />
            <span className="privacy-separator"></span>
            <span>
              All information submitted to SOS Capital is secure and
              confidential
            </span>
          </div>
          <div className="sos-socials">
            <a href="https://www.instagram.com/soscapital/" target="_blank">
              <img src={InstagramIcon} alt="instagram" />
            </a>
            <a
              href="https://www.linkedin.com/company/s-o-s-capital-inc-/"
              target="_blank"
            >
              <img src={LinkedinIcon} alt="linkedin" />
            </a>
            <a href="https://www.facebook.com/Soscapitalinc" target="_blank">
              <img src={FacebookIcon} alt="facebook" />
            </a>
            <a href="https://twitter.com/soscapital" target="_blank">
              <img src={TwitterIcon} alt="twitter" />
            </a>
          </div>
          <div className="footer-links">
            <a href="https://soscapital.com/legal-policy/" target="_blank">
              Legal Terms
            </a>
            <span className="privacy-separator"></span>
            <a
              href="https://soscapital.com/frequently-asked-questions/"
              target="_blank"
            >
              FAQ
            </a>
            <span className="privacy-separator"></span>
            <a
              href="https://soscapital.com/privacy-and-security-policy/"
              target="_blank"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </aside>
    </>
  );
};

export default AuthSidebar;
